import React, { Component } from "react"

/* Components */
import { Layout, Input } from "../../components"

/* REST API */
import { get, edit } from '../../api/Admin'

/* Icons */
import { LoadingOutlined } from '@ant-design/icons'

/* Helpers */
import { utils, withRouter } from "../../helpers"

/* Swal */
import Swal from 'sweetalert2'

/* Switch */
import Switch from "react-switch"

/* Redux */
import { connect } from 'react-redux'


/* Page Admin Edit */
class AdminEdit extends Component {

    constructor() {
        super()

        this.state = {
            status: "loading",
            data: [],
            name: "",
            email: "",
            extendedAccess: 0,
            team: "",
            roleID: 0,
            waiting: false
        }
    }

    componentDidMount = () => {
        this.load()
    }

    /* Load Roles */
    load = (reload = false) => {

        const { params } = this.props
        const { id } = params

        if (id) {

            if (reload) {
                this.setState({ status: 'loading' })
            }

            get({ id }).then(response => {
                if (response.status === 200) {
                    const data = response.data
                    this.setState({
                        status: "ok",
                        data: data.roles,
                        name: utils.checkValue(data.admin.name),
                        email: utils.checkValue(data.admin.email),
                        extendedAccess: utils.checkValue(data.admin.extendedAccess, 0),
                        team: utils.checkValue(data.admin.team, ""),
                        roleID: utils.checkValue(data.admin.roleID, 0)
                    })
                }
                else if (response.status === 401 || response.status === 403) {
                    this.setState({ status: "permission", data: [] })
                }
                else if (response.status === 498) {
                    this.setState({ status: "key", data: [] })
                }
                else {
                    this.setState({ status: "error", data: [] })
                }
            }).catch(() => {
                this.setState({ status: "network", data: [] })
            })

        }
        else {
            this.setState({ status: "error" })
        }

    }

    /* Save action */
    save = () => {

        const { name, email, roleID, extendedAccess, team } = this.state
        const { navigate, params } = this.props
        const { id } = params

        if (!id) {
            Swal.fire(utils.notification("Что-по пошло не так! Попробуйте позднее.", "error"))
            return
        }

        if (name === "" || name === null || name === undefined) {
            this._name.error("Введите свое имя")
            return
        }

        if (email === "" || email === null || email === undefined) {
            this._email.error("Введите Email")
            return
        }

        if (extendedAccess && team.length === 0) {
            this._command.error("Введите названия команды")
            return
        }

        if (/\S+@\S+\.\S+/.test(email) === false) {
            this._email.error("Неверный формат Email")
            return
        }

        if (roleID === 0) {
            Swal.fire(utils.notification("Выберите роль", "error"))
            return
        }

        this.setState({ waiting: true })

        edit({ id, name, email, roleID, access: extendedAccess, team }).then(response => {
            if (response.status === 200) {
                Swal.fire(utils.notification("Пользователь успешно добавлен", "success")).then(() => {
                    navigate(-1)
                })
            }
            else if (response.status === 401 || response.status === 403) {
                Swal.fire(utils.notification("Нет доступа!", "error"))
            }
            else if (response.status === 498) {
                Swal.fire(utils.notification("Неверный ключ!", "error"))
            }
            else {
                Swal.fire(utils.notification("Что-по пошло не так! Попробуйте позднее.", "error"))
            }
        }).catch(() => {
            Swal.fire(utils.notification("Что-по пошло не так! Попробуйте позднее.", "error"))
        }).finally(() => {
            this.setState({ waiting: false })
        })


    }

    /* Draw button */
    _button = () => {

        const { waiting } = this.state

        if (waiting) {
            return <div className="button"><LoadingOutlined /></div>
        }

        return <div onClick={() => this.save()} className="button">Сохранить</div>
    }

    render = () => {

        const { admin } = this.props
        const { status, name, email, roleID, data, extendedAccess, team } = this.state

        if (status !== "ok") {
            return <Layout status={status} reload={() => this.load(true)} />
        }

        return (
            <div className="page">

                <div className="page-header">
                    <h1>Редактировать пользователя</h1>
                </div>

                <div className="page-form">

                    <div className="form">
                        <div className="form-box">
                            <Input ref={ref => this._name = ref} label="Название" value={name} onChange={event => this.setState({ name: event.target.value })} />
                            <Input ref={ref => this._email = ref} label="Email" value={email} onChange={event => this.setState({ email: event.target.value })} />
                            <select value={roleID} onChange={event => this.setState({ roleID: event.target.value })}>
                                <option disabled value={0}>Выберите роль</option>
                                {data.map((item, index) => <option key={`${index}`} value={item.id}>{item.name}</option>)}
                            </select>

                            {
                                admin.isAdmin ?
                                    <div className="extended-access">
                                        <p>Расширенный доступ</p>
                                        <Switch uncheckedIcon={false} checkedIcon={false} onColor="#8bc34a" onChange={value => this.setState({ extendedAccess: value })} checked={extendedAccess} />
                                    </div>
                                    : null
                            }

                            {admin.isAdmin && extendedAccess ? <Input disabled={team && team.length > 0} ref={ref => this._command = ref} label="Названия команды" value={team} onChange={event => this.setState({ team: event.target.value })} /> : null}

                        </div>
                    </div>

                    <div className="form-bottom">
                        {this._button()}
                    </div>

                </div>

            </div>
        )
    }

}

const mapStateToProps = state => {
    return {
        admin: state.admin
    }
}

export default connect(mapStateToProps)(withRouter(AdminEdit))