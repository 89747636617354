
/* API */
import { adminOperators } from '../api/Operator'
import { getAdmin } from '../api/Admin'

/* JWT */
import { isExpired } from "react-jwt"


export const actionOperator = store => {

    const token = localStorage.getItem("token")

    if (token && !isExpired(token)) {
        adminOperators(token).then(response => {
            console.log(response.status)
            console.log(response.data)
            if (response.status === 200) {
                let list = []
                response.data.forEach(operator => {
                    list.push({ ...operator, checked: true })
                })
                store.dispatch({
                    type: 'SET_OPERATORS',
                    payload: list
                })
                setTimeout(() => {
                    store.dispatch({
                        type: 'SET_LOADING',
                        payload: false
                    })
                }, 1500)
            }
        })
    }
}

export const getAdminVariables = store => {

    const token = localStorage.getItem("token")

    if (token && !isExpired(token)) {
        getAdmin(token).then(response => {
            if (response.status === 200) {
                const data = response.data
                store.dispatch({
                    type: 'SET_ADMIN',
                    payload: data
                })
            }
        })
    }
}