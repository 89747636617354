import axios from "axios"
import { env } from '../constants'

/* Bonus List */
export const list = data => {
    const uri = `${env.startpoint}bonus/list`
    const token = localStorage.getItem("token")
    const headers = { headers: { Authorization: `Bearer ${token}` } }
    return axios.post(uri, data, headers).then(response => response).catch(data => data.response)
}

/* Bonus Operators */
export const bonus = () => {
    const uri = `${env.startpoint}bonus/bonus`
    const token = localStorage.getItem("token")
    const headers = { headers: { Authorization: `Bearer ${token}` } }
    return axios.get(uri, headers).then(response => response).catch(data => data.response)
}


/* Bonus Update */
export const update = data => {
    const uri = `${env.startpoint}bonus/update`
    const token = localStorage.getItem("token")
    const headers = { headers: { Authorization: `Bearer ${token}` } }
    return axios.post(uri, data, headers).then(response => response).catch(data => data.response)
}

/* Bonus Operators */
export const operators = () => {
    const uri = `${env.startpoint}bonus/operators`
    const token = localStorage.getItem("token")
    const headers = { headers: { Authorization: `Bearer ${token}` } }
    return axios.get(uri, headers).then(response => response).catch(data => data.response)
}

/* Bonus Connect */
export const connect = data => {
    const uri = `${env.startpoint}bonus/connect`
    const token = localStorage.getItem("token")
    const headers = { headers: { Authorization: `Bearer ${token}` } }
    return axios.post(uri, data, headers).then(response => response).catch(data => data.response)
}
