/* LOADING REDUX */

const DEFAULT = true

const admin = (state = DEFAULT, action) => {

    switch (action.type) {

        case 'SET_LOADING':
            return action.payload

        default:
            return state

    }
}

export default admin