import React, { Component } from "react"

/* Redux */
import { connect } from 'react-redux'

/* Components */
import { Layout } from '../../components'

/* REST API */
import { tables } from '../../api/Game'
import { utils } from "../../helpers"


/* Page Monitor */
class Monitor extends Component {

    constructor() {
        super()

        this.state = {
            data: [],
            status: "loading",

            operators: [],
            cnt: 0
        }
    }

    componentDidMount = () => {
        this.load(false, true)
    }

    /* Load actions */
    load = (reload = false, initial = false) => {

        const { operators, cnt } = this.state

        let list = operators

        if (initial) {
            list = this.props.operators
            this.setState({ operators: list })
        }

        if (!list || !Array.isArray(list) || list.length < 1) {
            setTimeout(() => {
                this.setState({ operators: this.props.operators, cnt: cnt + 1 },
                    () => cnt < 5 ? this.load(false, initial)
                        : this.setState({ status: "permission", data: null, cnt: 0 })
                )
            }, 700)
            return
        }

        if (reload) {
            this.setState({ status: "loading" })
        }

        const ids = utils.arrayID(list)

        tables({ ids }).then(response => {
            if (response.status === 200) {
                this.setState({ status: "ok", data: response.data })
            }
            else if (response.status === 401 || response.status === 403) {
                this.setState({ status: "permission", data: [] })
            }
            else if (response.status === 498) {
                this.setState({ status: "key", data: [] })
            }
            else {
                this.setState({ status: "error", data: [] })
            }
        }).catch(() => {
            this.setState({ status: "network", data: [] })
        })
    }

    render = () => {

        const { status, data } = this.state

        if (status !== "ok") {
            return <Layout status={status} reload={() => this.load(true)} />
        }

        return (
            <div className="page">

                <div className="page-header">
                    <h1>Мониторы дилера</h1>
                </div>

                <div className="monitors">
                    {data.map((table, index) =>
                        <a href={`${table.monitorURL ? `${table.monitorURL}/?${table.dealerMonitorToken}` : "#"}`} target="_blank" rel="noreferrer" className="monitor" key={`${index}`} style={table.monitorURL ? { opacity: 1, cursor: 'pointer' } : { opacity: 0.5, cursor: 'default', transform: "scale(1)" }}>
                            <div className="monitor-head">
                                <div className="monitor-head-text">Dealer Monitor</div>
                                <div className="monitor-head-dots">
                                    <div className="monitor-head-dot green" />
                                    <div className="monitor-head-dot yellow" />
                                    <div className="monitor-head-dot red" />
                                </div>
                            </div>
                            <div className="monitor-body">
                                <div className="monitor-name">{utils.checkValue(table.name, "-")}</div>
                                <div className="monitor-url">{utils.checkValue(table.monitorURL, "-")}</div>
                                <div className="monitor-token">{utils.checkValue(table.dealerMonitorToken, "-")}</div>
                            </div>
                        </a>
                    )}
                </div>

            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        operators: state.operators
    }
}

export default connect(mapStateToProps)(Monitor)