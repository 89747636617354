/* ADMIN REDUX */

const DEFAULT = {
    id: 0,
    name: "",
    isAdmin: 0,
    extendedAccess: 0,
    team: ""
}

const admin = (state = DEFAULT, action) => {

    switch (action.type) {

        case 'SET_ADMIN':
            return action.payload

        default:
            return state

    }
}

export default admin