import React, { Component } from "react"

import { adminOperators } from '../api/Operator'
import { getAdmin } from '../api/Admin'

/* Redux */
import { connect } from 'react-redux'

import Lottie from "lottie-react"

const LOADONG = require('../constants/mainloading.json')

class MainLoading extends Component {
    constructor() {
        super()
        this.state = {

        }
    }


    render = () => {

        const { loading, children } = this.props

        if (loading) {
            return (
                <div className="main-loading">
                    {/* <img src="/images/mainloading.json" alt="main-loading"/> */}
                    <Lottie animationData={LOADONG} loop={true} />
                </div>
            )
        }

        return children

    }

}


const mapStateToProps = state => {
    return {
        loading: state.loading
    }
}

export default connect(mapStateToProps)(MainLoading)
