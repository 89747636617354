/* OPERATORS REDUX */

const operator = (state = [], action) => {

    switch (action.type) {

        case 'SET_OPERATORS':
            return action.payload

        default:
            return state
    }
}

export default operator