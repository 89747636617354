import { configureStore } from '@reduxjs/toolkit'

/* Reducers */
import operators from './operators'
import admin from './admin'
import loading from './loading'



/* Actions */
import { actionOperator, getAdminVariables } from './actions'

const store = configureStore({
    reducer: {
        operators,
        admin,
        loading
    }
})

actionOperator(store)
getAdminVariables(store)

export { store }