import React, { Component } from "react"

class OperatorFilter extends Component {
    constructor() {
        super()
        this.state = {
            visible: false,
        }
    }

    componentDidMount = () => {
        document.addEventListener('click', this.handleClickOutside, true)
    }

    componentWillUnmount = () => {
        document.removeEventListener('click', this.handleClickOutside, true)
    }

    /* Click Outside Handler */
    handleClickOutside = event => {

        if (this._menu && !this._menu.contains(event.target) && this._btn && !this._btn.contains(event.target)) {
            this.setState({ visible: false })
        }

    }

    apply = () => {
        const { apply } = this.props
        apply()
        this.setState({ visible: false })
    }

    change = (index) => {

        const { operators, setOperators } = this.props

        const tempOperators = operators.map((operator, ind) =>
            ind === index ? { ...operator, checked: !operator.checked } : operator
        )

        const hasChecked = tempOperators.some(operator => operator.checked)

        if (hasChecked) {
            setOperators(tempOperators)
        }

    }

    render = () => {

        const { operators, mt } = this.props

        const { visible } = this.state

        const filtered = operators.filter(e => e.checked === true)

        return (
            <div className="o-filter" style={{marginTop: mt ? mt : 0}}>
                <div ref={ref => this._btn = ref} className="o-filter-btn" onClick={() => this.setState({ visible: !visible })}>
                    <p className="o-filter-btn-text">Операторы <span className="count">{filtered.length}</span></p>
                </div>

                <div ref={ref => this._menu = ref} className={`o-filter-dropdown-menu ${visible ? 'active' : ''}`}>
                    {
                        operators.map((item, index) => {

                            let style = index === operators.length - 1 ? "last" : ""
                            let checkStyle = ""

                            if (item.checked) {
                                checkStyle += " checked"
                            }

                            return (
                                <div className={`o-filter-dropdown-menu-item ${style}`} key={`o-filter-${index}`} onClick={() => this.change(index)}>
                                    <span>{item.name}</span>
                                    <div className={`o-filter-check-block ${checkStyle}`}>
                                        {item.checked ? <p>&#10003;</p> : null}
                                    </div>
                                </div>
                            )
                        })
                    }

                    <div className="o-filter-bottom">
                        <div className={`o-filter-button active`} onClick={() => this.apply()}>
                            <p className="apply">Применить</p>
                        </div>
                    </div>

                </div>

            </div>
        )

    }

}

export default OperatorFilter