const data = [

    /* Electron Poker */
    { name: "Русский Электронный Покер", key: "russian-electron-poker", type: "electron" },
    { name: "Русский 2 BOX Электронный Покер", key: "russian-two-box-electron-poker", type: "electron" },
    { name: "Cупер Покер", key: "super-electron-poker", type: "electron" },
    { name: "Открытый Электронный Покер", key: "open-electron-poker", type: "electron" },
    { name: "Электронный Пиковая Дама", key: "queen-electron-poker", type: "electron" },
    { name: "Электронный Ultimate Texas Hold'em", key: "ultimate-electron-poker", type: "electron" },
    { name: "Электронный Европейский Покер", key: "european-electron-poker", type: "electron" },
    { name: "Электронный Casino Hold'em", key: "casino-holdem-electron-poker", type: "electron" },
    { name: "Электронный NOVA POKER", key: "nova-electron-poker", type: "electron" },
    { name: "Русский J Покер", key: "j-electron-poker", type: "electron" },

    /* MULTI */
    { name: "Русский мульти покер", key: "russian-multipoker", type: "multi" },
    { name: "Открытый покер", key: "open-multipoker", type: "multi" },
    { name: "Пиковая дама мульти покер", key: "queen-multipoker", type: "multi" },
    { name: "Европейский мульти покер", key: "european-multipoker", type: "multi" },
    { name: "Ultimate Texas Hold'em", key: "ultimate-multipoker", type: "multi" },
    { name: "Casino Hold'em", key: "holdem-multipoker", type: "multi" },

    /* VIP */
    { name: "Русский VIP покер", key: "russian-vip-poker", type: "vip" },
    { name: "Пиковая дама VIP покер", key: "queen-vip-poker", type: "vip" },
    { name: "Европейский VIP покер", key: "european-vip-poker", type: "vip" },
]

const gameSettings = [
    { index: 0, type: 'electron', name: "ELECTRONIC GAMES" },
    { index: 1, type: 'multi', name: "MULTIPLAYER GAMES" },
    { index: 2, type: 'vip', name: "VIP GAMES"}
]
// const gameTypeIndex = { "electron": { index: 0, name: "ELECTRONIC GAMES" }, "multi": { index: 1, name: "MULTI GAMES" }, "vip": { index: 2, name: "VIP GAMES" } }

export { data, gameSettings }