import React, { Component } from "react"

/* Components */
import { Input } from "../../components"

/* Modal */
import Modal from 'react-modal'

/* Switch */
import Switch from "react-switch"

/* Helpers */
import { utils } from '../../helpers'

/* Sweet alert */
import Swal from "sweetalert2"

/* REST API */
import { update } from "../../api/Bonus"

/* Icons */
import { LoadingOutlined } from '@ant-design/icons'


/* Widget Jackpot Update */
class JackpotUpdate extends Component {

    constructor(props) {
        super(props)

        const { data } = this.props

        const jackpot = data && data.jackpot ? String(Number(data.jackpot).toFixed(2)).replace(/\B(?=(\d{3})+(?!\d))/g, " ") : "0"
        const score = data && data.score ? String(Number(data.score).toFixed(2)).replace(/\B(?=(\d{3})+(?!\d))/g, " ") : "0"
        const enabled = data && data.enabled && data.enabled === 1 ? true : false

        this.state = {
            jackpot,
            score,
            enabled,
            waiting: false
        }
    }


    /* Jackpot Change */
    jackpotChange = value => {

        if (value === null || value === undefined || value === "") {
            this.setState({ jackpot: "0" })
            return
        }

        const str = value.replaceAll(' ', '')
        const digit = str.replace(/\D\./, '')
        const total = parseFloat(digit).toString()
        const split = total.split(".")

        if (split.length === 1) {
            const final = total.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ")
            this.setState({ jackpot: final })
        }

        if (split.length === 2) {
            const final = `${split[0].toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ")}.${split[1]}`
            this.setState({ jackpot: final })
        }
    }

    /* Score Change */
    scoreChange = value => {

        if (value === null || value === undefined || value === "") {
            this.setState({ score: "0" })
            return
        }

        const str = value.replaceAll(' ', '')
        const digit = str.replace(/\D\./, '')
        const total = parseFloat(digit).toString()
        const split = total.split(".")

        if (split.length === 1) {
            const final = total.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ")
            this.setState({ score: final })
        }

        if (split.length === 2) {
            const final = `${split[0].toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ")}.${split[1]}`
            this.setState({ score: final })
        }
    }

    /* Save Action */
    save = () => {

        const { jackpot, score, enabled } = this.state
        const { socket, data } = this.props

        const operatorID = data && data.id ? data.id : 0

        if (operatorID === 0) {
            Swal.fire(utils.notification("Что-то пошло не так!<br /> Попробуйте позднее!", "error"))
            return
        }

        const jackpotStr = jackpot.replaceAll(' ', '')
        const jackpotTotal = parseFloat(jackpotStr)

        const scoreStr = score.replaceAll(' ', '')
        const scoreTotal = parseFloat(scoreStr)

        this.setState({ waiting: true })

        update({ operatorID, jackpot: jackpotTotal, score: scoreTotal, enabled: enabled ? 1 : 0 }).then(response => {
            if (response.status === 200) {
                socket.emit("jackpotUpdate", { operatorID, jackpot: jackpotTotal })
                Swal.fire(utils.notification("Данные успешно сохранены!", "success"))
            }
            else {
                Swal.fire(utils.notification("Что-то пошло не так!<br /> Попробуйте позднее!", "error"))
            }
        }).catch(() => {
            Swal.fire(utils.notification("Что-то пошло не так!<br /> Попробуйте позднее!", "error"))
        }).finally(() => {
            this.setState({ waiting: false })
        })
    }

    render = () => {

        const { visible, close, data } = this.props
        const { jackpot, score, enabled, waiting } = this.state

        return (
            <Modal
                isOpen={visible}
                onRequestClose={() => close()}
                className="bonus-jackpot--modal bonus-jackpot--update"
                overlayClassName="bonus-jackpot--overlay"
                closeTimeoutMS={200}
            >

                {data &&
                    <div className="bonus-jackpot--modal-header">
                        <h2>{data.name}</h2>
                        <div onClick={() => close()} className="bonus-jackpot--modal-close">
                            <img src="/images/close.png" alt="Close" />
                        </div>
                    </div>
                }

                <div className="bonus-jackpot--modal-update">
                    <Input label="JACKPOT, $" value={jackpot} onChange={event => this.jackpotChange(event.target.value)} />
                    <Input label="Касса, $" value={score} onChange={event => this.scoreChange(event.target.value)} />
                    <div className="bonus-jackpot--modal-switch">
                        <p>Видимость</p>
                        <Switch
                            uncheckedIcon={false}
                            checkedIcon={false}
                            onColor="#8bc34a"
                            onChange={value => this.setState({ enabled: value })}
                            checked={enabled}
                        />
                    </div>

                    <div onClick={() => !waiting && this.save()} className="bonus-jackpot--modal-button">
                        {waiting ? <LoadingOutlined /> : "Отправить"}
                    </div>

                </div>

            </Modal>
        )
    }

}

export default JackpotUpdate