import React, { Component } from "react"

/* Redux */
import { connect } from 'react-redux'

/* env */
import { env } from "../../constants"

/* Components */
import { Input } from '../../components'

/* Icons */
import { LoadingOutlined } from '@ant-design/icons'

/* Widgets */
import Layout from './Layout'

/* Swal */
import Swal from 'sweetalert2'

/* Helpers */
import { utils, withRouter } from '../../helpers'

/* REST API */
import { get, save, remove } from '../../api/Table'

/* Switch */
import Switch from "react-switch"

/* Copy To Clipboard */
import { CopyToClipboard } from 'react-copy-to-clipboard'



/* Widget Settings */
class Settings extends Component {

    constructor() {
        super()

        this.state = {
            status: "loading",
            data: null,
            waiting: false,

            name: "",
            slug: "",
            game: "",
            gameURL: "",
            backURL: "",
            monitorURL: "",
            hlsURL: "",
            backColor: "",

            gameImgUrl: "",
            gameImg: null,

            visible: false,
            available: false,
        }
    }

    componentDidMount = () => {
        this.load()
    }

    /* Load Table Data */
    load = (reload = false) => {

        /* Fields */
        const { uid, operators } = this.props

        /* Check Params */
        if (!uid && !(operators && Array.isArray(operators) && operators.length > 0)) {
            this.setState({ status: 'error' })
            return
        }

        /* Refresh the page if necessary */
        if (reload) {
            this.setState({ status: 'loading' })
        }

        /* START REST API */
        get({ uid, ids: utils.arrayID(operators) }).then(response => {
            if (response.status) {
                const data = response.data

                this.setState({
                    status: "ok",
                    data,
                    name: utils.checkValue(data.name),
                    slug: utils.checkValue(data.slug),
                    game: utils.checkValue(data.game),
                    gameURL: utils.checkValue(data.gameURL),
                    monitorURL: utils.checkValue(data.monitorURL),
                    backURL: utils.checkValue(data.backURL),
                    hlsURL: utils.checkValue(data.hlsURL),
                    backColor: utils.checkValue(data.backColor),
                    gameImgUrl: data.gameImg ? `${env.point}/images/table-images/${data.gameImg}` : null,
                    visible: utils.checkValue(data.visible, false),
                    available: utils.checkValue(data.available, false)
                })

            }
            else if (response.status === 401 || response.status === 403) {
                this.setState({ status: "permission" })
            }
            else if (response.status === 498) {
                this.setState({ status: "key" })
            }
            else {
                this.setState({ status: "error" })
            }
        }).catch(() => {
            this.setState({ status: "network" })
        })
    }


    /* Remove Table */
    remove = () => {

        /* Fields */
        const { uid, navigate } = this.props

        /* Check Params */
        if (!uid) {
            Swal.fire(utils.notification("Неизвестная ошибка! Пожалуйста, повторите попытку позже.", "error"))
            return
        }

        Swal.fire({
            title: "Удалить игру?",
            text: "После удаления восстановить невозможно.",
            showCancelButton: true,
            cancelButtonColor: "#999",
            cancelButtonText: "Отменить",
            confirmButtonColor: "#f44336",
            confirmButtonText: "Удалить",
        }).then(result => {
            if (result.isConfirmed) {
                remove({ uid }).then(response => {
                    if (response.status === 200) {
                        Swal.fire(utils.notification("Игра удалена!", "success")).then(() => navigate("/game"))
                    }
                    else {
                        Swal.fire(utils.notification("Неизвестная ошибка! Пожалуйста, повторите попытку позже.", "error"))
                    }
                }).catch(() => {
                    Swal.fire(utils.notification("Неизвестная ошибка! Пожалуйста, повторите попытку позже.", "error"))
                })
            }
        })
    }

    /* Save Action */
    save = () => {

        /* Fields */
        const { name, slug, game, gameURL, backURL, hlsURL, monitorURL, backColor, gameImg, visible, available } = this.state
        const { uid } = this.props

        /* Check Params */
        if (!uid) {
            Swal.fire(utils.notification("Неизвестная ошибка! Пожалуйста, повторите попытку позже.", "error"))
            return
        }

        if (name === "") {
            this._name.error("Введите название стола")
            return
        }

        if (slug === "") {
            this._slug.error("Введите ключ стола")
            return
        }

        if (game === "") {
            this._slug.error("Введите название игры")
            return
        }

        let data = new FormData()

        data.append("uid", uid)
        data.append("name", name)
        data.append("slug", slug)
        data.append("game", game)
        data.append("gameURL", gameURL)
        data.append("backURL", backURL)
        data.append("monitorURL", monitorURL)
        data.append("hlsURL", hlsURL)
        data.append("backColor", backColor)
        data.append("visible", visible ? 1 : 0)
        data.append("available", available ? 1 : 0)

        if (gameImg) {
            data.append("gameImg", gameImg)
        }
        this.setState({ waiting: true })

        save(data).then(response => {
            if (response.status === 200) {
                Swal.fire(utils.notification("Данные успешно сохранены! <br /> Изменения появятся<br /> после обновления страницы.", "success", 3000))
            }
            else if (response.status === 401 || response.status === 403) {
                Swal.fire(utils.notification("Нет доступа!", "error"))
            }
            else if (response.status === 498) {
                Swal.fire(utils.notification("Неверный ключ!", "error"))
            }
            else {
                Swal.fire(utils.notification("Что-по пошло не так! Попробуйте позднее.", "error"))
            }
        }).catch(() => {
            Swal.fire(utils.notification("Что-по пошло не так! Попробуйте позднее.", "error"))
        }).finally(() => {
            this.setState({ waiting: false })
        })

    }

    /* Add game image */
    addGameImg = (event) => {
        const img = event.target.files[0]
        if (img) {
            this.setState({ gameImg: img, gameImgUrl: URL.createObjectURL(img) })
        }
    }

    /* Draw Button */
    _button = () => {

        const { waiting } = this.state

        if (waiting) {
            return <div className="table--settings-button"><LoadingOutlined /></div>
        }

        return <div onClick={() => this.save()} className="table--settings-button">Сохранить</div>
    }

    render = () => {

        const { status, data, name, slug, game, gameURL, backURL, hlsURL, backColor, monitorURL, gameImgUrl, visible, available } = this.state

        if (status !== "ok") {
            return <Layout status={status} reload={() => this.load(true)} />
        }

        return (
            <div className="table--modal-content table--settings">

                <Input label="Название стола" ref={ref => this._name = ref} value={name} onChange={event => this.setState({ name: event.target.value })} />
                <Input label="Ключ стола" ref={ref => this._slug = ref} value={slug} onChange={event => this.setState({ slug: event.target.value })} />
                <Input label="Игра" ref={ref => this._game = ref} value={game} onChange={event => this.setState({ game: event.target.value })} />
                <Input label="URL игры" value={gameURL} onChange={event => this.setState({ gameURL: event.target.value })} />
                <Input label="URL сервера" value={backURL} onChange={event => this.setState({ backURL: event.target.value })} />
                <Input label="URL монитора дилера" value={monitorURL} onChange={event => this.setState({ monitorURL: event.target.value })} />
                <Input label="HLS URL" value={hlsURL} onChange={event => this.setState({ hlsURL: event.target.value })} />
                <Input label="Background color" value={backColor} onChange={event => this.setState({ backColor: event.target.value })} />
                <Input file label="Изображение" value={gameImgUrl} onChange={event => this.addGameImg(event)} />

                {gameImgUrl ? <img className="back-img" src={gameImgUrl} alt='gameImg' /> : null}

                <div className="table---settings-row">
                    <span>Видимость стола</span>
                    <Switch uncheckedIcon={false} checkedIcon={false} onColor="#8bc34a" onChange={value => this.setState({ visible: value })} checked={visible} />
                </div>

                <div className="table---settings-row">
                    <span>Стол занят</span>
                    <Switch uncheckedIcon={false} checkedIcon={false} onColor="#8bc34a" onChange={value => this.setState({ available: value })} checked={available} />
                </div>

                <div className="table---settings-element">
                    <span>Текущий Дилер</span>
                    <p>{utils.checkValue(data.dealer, "-")}</p>
                </div>

                <CopyToClipboard onCopy={() => Swal.fire(utils.notification("Токен успешно скопирован!", "success"))} text={utils.checkValue(data.uid, "-")}>
                    <div className="table---settings-element">
                        <span>UNIQUE TOKEN</span>
                        <p>{utils.checkValue(data.uid, "-")}</p>
                        <img src="/images/copy.png" alt="Copy" />
                    </div>
                </CopyToClipboard>

                <CopyToClipboard onCopy={() => Swal.fire(utils.notification("Токен успешно скопирован!", "success"))} text={utils.checkValue(data.dealerSocketToken, "-")}>
                    <div className="table---settings-element">
                        <span>DEALER MIDDLEWARE TOKEN</span>
                        <p>{utils.checkValue(data.dealerSocketToken, "-")}</p>
                        <img src="/images/copy.png" alt="Copy" />
                    </div>
                </CopyToClipboard>

                <CopyToClipboard onCopy={() => Swal.fire(utils.notification("Токен успешно скопирован!", "success"))} text={utils.checkValue(data.dealerMonitorToken, "-")}>
                    <div className="table---settings-element">
                        <span>DEALER MONITOR TOKEN</span>
                        <p>{utils.checkValue(data.dealerMonitorToken, "-")}</p>
                        <img src="/images/copy.png" alt="Copy" />
                    </div>
                </CopyToClipboard>

                <CopyToClipboard onCopy={() => Swal.fire(utils.notification("Токен успешно скопирован!", "success"))} text={utils.checkValue(data.scannerToken, "-")}>
                    <div className="table---settings-element">
                        <span>SCANNER TOKEN</span>
                        <p>{utils.checkValue(data.scannerToken, "-")}</p>
                        <img src="/images/copy.png" alt="Copy" />
                    </div>
                </CopyToClipboard>

                <CopyToClipboard onCopy={() => Swal.fire(utils.notification("Токен успешно скопирован!", "success"))} text={utils.checkValue(data.adminToken, "-")}>
                    <div className="table---settings-element">
                        <span>ADMIN TOKEN</span>
                        <p>{utils.checkValue(data.adminToken, "-")}</p>
                        <img src="/images/copy.png" alt="Copy" />
                    </div>
                </CopyToClipboard>

                <CopyToClipboard onCopy={() => Swal.fire(utils.notification("Токен успешно скопирован!", "success"))} text={utils.checkValue(data.atemToken, "-")}>
                    <div className="table---settings-element">
                        <span>ATEM TOKEN</span>
                        <p>{utils.checkValue(data.atemToken, "-")}</p>
                        <img src="/images/copy.png" alt="Copy" />
                    </div>
                </CopyToClipboard>

                <div className="table--settings-remove" onClick={() => this.remove()}>Удалить игру</div>

                <div className="table--settings-bottom">
                    {this._button()}
                </div>

            </div>
        )
    }

}


const mapStateToProps = state => {
    return {
        admin: state.admin,
        operators: state.operators
    }
}

export default connect(mapStateToProps)(withRouter(Settings))