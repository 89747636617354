import React, { Component } from "react"

/* Componenent */
import { InnerLayout, Input } from "../../components"

/* Modal */
import Modal from "react-modal"

/* Switch */
import Switch from "react-switch"

/* Helpers */
import { utils } from '../../helpers'

/* Sweet alert */
import Swal from "sweetalert2"

/* REST API */
import { operators, connect } from "../../api/Bonus"

/* Icons */
import { LoadingOutlined } from '@ant-design/icons'



/* Widgtet Jackpot Connect */
class JackpotConnect extends Component {

    constructor() {
        super()

        this.state = {
            status: "loading",
            data: [],
            operatorID: 0,
            jackpot: "0",
            score: "0",
            enabled: false
        }
    }

    componentDidMount = () => {
        this.load()
    }

    /* Load action */
    load = () => {
        operators().then(response => {
            if (response.status === 200) {
                this.setState({ status: "ok", data: response.data })
            }
            else if (response.status === 401 || response.status === 403) {
                this.setState({ status: "permission", data: [] })
            }
            else if (response.status === 498) {
                this.setState({ status: "key", data: [] })
            }
            else {
                this.setState({ status: "error", data: [] })
            }
        }).catch(() => {
            this.setState({ status: "network", data: [] })
        })
    }

    /* Select Operator */
    selectOperator = data => {

        if (data.operatorID === null) {
            this.setState({ operatorID: data.id })
        }

    }

    /* Jackpot Change */
    jackpotChange = value => {

        if (value === null || value === undefined || value === "") {
            this.setState({ jackpot: "0" })
            return
        }

        const str = value.replaceAll(' ', '')
        const digit = str.replace(/\D\./, '')
        const total = parseFloat(digit).toString()
        const split = total.split(".")

        if (split.length === 1) {
            const final = total.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ")
            this.setState({ jackpot: final })
        }

        if (split.length === 2) {
            const final = `${split[0].toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ")}.${split[1]}`
            this.setState({ jackpot: final })
        }
    }

    /* Score Change */
    scoreChange = value => {

        if (value === null || value === undefined || value === "") {
            this.setState({ score: "0" })
            return
        }

        const str = value.replaceAll(' ', '')
        const digit = str.replace(/\D\./, '')
        const total = parseFloat(digit).toString()
        const split = total.split(".")

        if (split.length === 1) {
            const final = total.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ")
            this.setState({ score: final })
        }

        if (split.length === 2) {
            const final = `${split[0].toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ")}.${split[1]}`
            this.setState({ score: final })
        }
    }


    /* Connect */
    connect = () => {

        const { operatorID, jackpot, score, enabled } = this.state
        const { close } = this.props

        if (operatorID === 0) {
            Swal.fire(utils.notification("Выберите оператора", "warning"))
            return
        }

        const jackpotStr = jackpot.replaceAll(' ', '')
        const jackpotTotal = parseFloat(jackpotStr)

        const scoreStr = score.replaceAll(' ', '')
        const scoreTotal = parseFloat(scoreStr)

        this.setState({ waiting: true })

        connect({ operatorID, jackpot: jackpotTotal, score: scoreTotal, enabled: enabled ? 1 : 0 }).then(response => {
            if (response.status === 200) {
                Swal.fire(utils.notification("Данные успешно сохранены!", "success"))
                close()
            }
            else {
                Swal.fire(utils.notification("Что-то пошло не так!<br /> Попробуйте позднее!", "error"))
            }
        }).catch(() => {
            Swal.fire(utils.notification("Что-то пошло не так!<br /> Попробуйте позднее!", "error"))
        }).finally(() => {
            this.setState({ waiting: false })
        })
    }


    /* Draw Content */
    _content = () => {

        /* Fields */
        const { status, data, operatorID, jackpot, score, enabled, waiting } = this.state

        /* Layout */
        if (status !== "ok") {
            return <InnerLayout status={status} reload={() => this.load()} />
        }

        return (
            <div className="bonus-jackpot--connect-container">

                <div className="bonus-jackpot--connect-operator-label">Операторы</div>
                <div className="bonus-jackpot--connect-operators">
                    {data.map((item, index) =>
                        <div onClick={() => this.selectOperator(item)} className={`bonus-jackpot--connect-operator ${operatorID === item.id ? "bonus-jackpot--connect-operator-selected" : ""} ${item.operatorID !== null ? 'bonus-jackpot--connect-operator-disabled' : ''}`} key={`${index}`}>{item.name}</div>
                    )}
                </div>

                <Input label="JACKPOT, $" value={jackpot} onChange={event => this.jackpotChange(event.target.value)} />
                <Input label="Касса, $" value={score} onChange={event => this.scoreChange(event.target.value)} />

                <div className="bonus-jackpot--modal-switch">
                    <p>Видимость</p>
                    <Switch
                        uncheckedIcon={false}
                        checkedIcon={false}
                        onColor="#8bc34a"
                        onChange={value => this.setState({ enabled: value })}
                        checked={enabled}
                    />
                </div>

                <div onClick={() => !waiting && this.connect()} className="bonus-jackpot--modal-button">
                    {waiting ? <LoadingOutlined /> : "Соединить"}
                </div>

            </div>
        )
    }

    render = () => {

        const { visible, close } = this.props

        return (
            <Modal
                isOpen={visible}
                onRequestClose={() => close()}
                className="bonus-jackpot--modal bonus-jackpot--connect"
                overlayClassName="bonus-jackpot--overlay"
                closeTimeoutMS={200}
            >

                <div className="bonus-jackpot--modal-header">
                    <h2>Подключить JACKPOT</h2>
                    <div onClick={() => close()} className="bonus-jackpot--modal-close">
                        <img src="/images/close.png" alt="Close" />
                    </div>
                </div>

                {this._content()}

            </Modal>
        )
    }

}

export default JackpotConnect