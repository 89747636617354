import React, { Component } from "react"

/* Helpers */
import { utils } from '../helpers'



/* Component Process */
class Process extends Component {

    /* Get types */
    getTypes = () => {

        const { data } = this.props

        let types = []
        data.forEach(item => {
            const index = types.findIndex(e => e === item.type)
            if (index === -1) {
                types.push(item.type)
            }
        })

        return types
    }


    /* Draw Box */
    _box = () => {

        const { data, count, game } = this.props
        const size = game === "multi" ? 1 : count
        const boxes = [0, 1]
        const types = this.getTypes()

        const list = data
        list.forEach(item => {
            if (!item.box) {
                item.box = "1"
            }
        })

        return (
            <div className="process--boxes">
                {boxes.map((box, index) => {

                    const ind = data.findIndex(e => parseInt(e.box) === box + 1)

                    if (ind !== -1) {
                        return (
                            <div className="process--box" key={`${index}`}>
                                {game === "multi" ? <div className="process--box-number">HAND</div> : <div className="process--box-number">{box + 1} BOX</div>}
                                {types.map(type => this._process(box + 1, type))}
                            </div>
                        )
                    }

                }
                )}
            </div>
        )
    }

    /* Draw Process */
    _process = (box, type) => {

        const { data, symbol, game } = this.props

        const list = data
        list.forEach(item => {
            if (!item.box) {
                item.box = "1"
            }
        })

        const index = game === "multi" ? data.findIndex(e => e.type === type) : list.findIndex(e => e.type === type && parseInt(e.box) === parseInt(box))

        if (index > -1) {

            const datetime = utils.getTime(data[index].createdAt)

            return (
                <div className="process--box-element" key={`${type}`}>
                    <div className={`process--box-title ${type}`}>{data[index].type}</div>
                    <div className="process--box-value">{utils.convertor(data[index].total, symbol)}</div>
                    <div className="process--box-reason">{data[index].reason}</div>
                    <div className="process--box-datetime">{datetime.time}, <span>{datetime.date}</span></div>
                </div>
            )
        }

    }

    render = () => {

        const { data, count } = this.props

        if (data && Array.isArray(data) && data.length > 0 && count && parseInt(count) > 0) {
            return (
                <div className="process--container">
                    <div className="process--container-title">Game Processes</div>
                    {this._box()}
                </div>
            )
        }

        return <div />
    }

}

export default Process