import React, { Component } from "react"

/* Components */
import { Layout, Tag, Pagination, Reload, OperatorFilter } from '../../components'

/* Helpers */
import { utils } from '../../helpers'

/* REST API */
import { games } from '../../api/OEP'

/* Redux */
import { connect } from 'react-redux'

/* Moment */
import moment from 'moment'
import 'moment/locale/ru'

/* DatePicker */
import DatePicker, { registerLocale } from 'react-datepicker'
import ru from "date-fns/locale/ru"

/* DatePicker Settings */
registerLocale("ru", ru)

/* Moment Settings */
moment.locale("ru")


/* Fields */
const PERCENTAGE = 95 / 6


/* Widget OEPGame */
class OEPGames extends Component {

    constructor() {
        super()

        const date = utils.getDate()

        this.state = {
            data: [],
            status: 'ok',
            page: 1,
            count: 0,
            number: '',
            playerID: '',
            startDate: date.startDate,
            endDate: date.endDate,
            toggleFilter: false,

            operators: [],
            cnt: 0
        }
    }

    componentDidMount = () => {
        this.load(1, false, true)
    }

    /* Load Games list */
    load = (page = 1, reload = false, initial = false) => {

        /* Fields */
        const { number, playerID, startDate, endDate, operators, cnt } = this.state
        const start = moment(startDate).format('YYYY-MM-DD HH:mm:ss')
        const end = moment(endDate).format('YYYY-MM-DD HH:mm:ss')
        const { uid } = this.props

        /* Check Params */
        if (!uid) {
            this.setState({ status: 'error', data: [] })
            return
        }

        let list = operators

        if (initial) {
            list = this.props.operators
            this.setState({ operators: list })
        }

        if (!list || !Array.isArray(list) || list.length < 1) {
            setTimeout(() => {
                this.setState({ operators: this.props.operators, cnt: cnt + 1 },
                    () => cnt < 5 ? this.load(1, false, initial)
                        : this.setState({ status: "permission", data: null, cnt: 0 })
                )
            }, 700)
            return
        }

        /* Save Page */
        this.setState({ page })

        /* Reload */
        if (reload) {
            this.setState({ status: 'loading' })
        }

        const ids = utils.arrayID(list)

        /* START REST API */
        games({ uid, ids, page, number, playerID, start, end }).then(response => {
            if (response.status === 200) {
                this.setState({ status: "ok", data: response.data.list, count: response.data.count })
            }
            else if (response.status === 401 || response.status === 403) {
                this.setState({ status: "permission", data: [], count: 0, cnt: 0 })
            }
            else if (response.status === 498) {
                this.setState({ status: "key", data: [], count: 0, cnt: 0 })
            }
            else {
                this.setState({ status: "error", data: [], count: 0, cnt: 0 })
            }
        }).catch(() => {
            this.setState({ status: "network", data: [], count: 0, cnt: 0 })
        })

    }

    /* On Submit */
    search = event => {
        event.preventDefault()
        this.load(1)
    }

    /* Set Number ACtion */
    setNumber = number => {
        if (number === "") {
            this.setState({ number }, () => this.load(1))
        }
        else {
            this.setState({ number })
        }
    }


    /* Reload Game */
    reloadGames = () => {
        const { page } = this.state
        this.load(page, true)
    }


    /* Draw time */
    _time = time => {

        const datetime = utils.getTime(time)

        return (
            <div className="page-table-datetime">
                <div className="page-table-time">{datetime.time}</div>
                <div className="page-table-date">{datetime.date}</div>
            </div>
        )
    }


    render = () => {

        const { status, data, page, count, number, playerID, startDate, endDate, toggleFilter, operators } = this.state
        const { openGame } = this.props

        if (status !== 'ok') {
            return <Layout status={status} inner />
        }

        return (
            <div className="oep--game-list">

                <div className="oep--game-filter">

                    <div className="oep--number-filter-box">

                        <div className="oep--number-filter">
                            <form onSubmit={this.search}>
                                <input placeholder="Поиск по номеру игры" value={number} onChange={event => this.setNumber(event.target.value)} />
                                <button onClick={() => this.load(1)}><img src="/images/search.png" alt="Search" /></button>
                            </form>
                        </div>

                        <div className="game-player-filter">
                            <form onSubmit={this.search}>
                                <input placeholder="ID игрока" value={playerID} onChange={event => this.setState({ playerID: event.target.value })} />
                                <button onClick={() => this.load(1)}><img src="/images/search.png" alt="Search" /></button>
                            </form>
                        </div>

                        <Reload status={status} reload={() => this.load(1, true)} />

                        <div onClick={() => this.setState({ toggleFilter: !toggleFilter })} className="oep--number-mobile-filter">
                            <img src="/images/game/filter.png" alt="Filter" />
                        </div>

                        {operators && operators.length > 0 ? <OperatorFilter operators={operators} setOperators={list => this.setState({ operators: list })} apply={() => this.load(1, true)} /> : null}
                        
                    </div>


                    <div className={`oep--date-filter ${toggleFilter ? 'active' : 'disabled'}`}>

                        <span>c</span>

                        <DatePicker
                            selected={startDate}
                            onChange={date => this.setState({ startDate: date })}
                            selectsStart
                            startDate={startDate}
                            endDate={endDate}
                            dateFormat="dd-MM-yyyy"
                            locale="ru"
                        />

                        <span>до</span>

                        <DatePicker
                            selected={endDate}
                            onChange={date => this.setState({ endDate: date })}
                            selectsEnd
                            startDate={startDate}
                            endDate={endDate}
                            minDate={startDate}
                            dateFormat="dd-MM-yyyy"
                            locale="ru"
                        />

                        <button onClick={() => this.load(1)}>Применить</button>

                    </div>

                </div>

                <div className="oep--game-content">

                    <div className="oep--content-head">
                        <div className="oep--content-element number">#</div>
                        <div className="oep--content-element" style={{ width: `${PERCENTAGE}%` }}>Номер игры</div>
                        <div className="oep--content-element" style={{ width: `${PERCENTAGE}%` }}>Игрок</div>
                        <div className="oep--content-element" style={{ width: `${PERCENTAGE}%` }}>Оператор</div>
                        <div className="oep--content-element" style={{ width: `${PERCENTAGE}%` }}>Статус</div>
                        <div className="oep--content-element" style={{ width: `${PERCENTAGE}%` }}>Дата</div>
                    </div>

                    {data.map((item, index) =>
                        <div className="oep--content-row" key={`${index}`} onClick={() => openGame(item.id, item.number)}>
                            <div className="oep--content-element number">{(page - 1) * 10 + index + 1}</div>
                            <div className="oep--content-element" style={{ width: `${PERCENTAGE}%` }}>
                                <div onClick={() => openGame(item.id, item.number)} className="link">#{item.number}</div>
                            </div>
                            <div className="oep--content-element" style={{ width: `${PERCENTAGE}%` }}>
                                <b>{item.gamePlayer ? utils.checkValue(item.gamePlayer.firstName) : null}</b><br />
                                <small className="inLine">{item.player} {item.gamePlayer && parseInt(item.gamePlayer.isTester) === 1 && <span className="p-tester">(Тестировщик)</span>}</small>
                            </div>
                            <div className="oep--content-element" style={{ width: `${PERCENTAGE}%` }}>
                                <Tag color="orange" right={10} uppercase>{item.gamePlayer ? utils.checkValue(item.gamePlayer.operator) : null}</Tag>
                            </div>
                            <div className="oep--content-element" style={{ width: `${PERCENTAGE}%` }}>
                                {parseInt(item.isPaused) === 1 ? <Tag color="red" right={10}>На паузе</Tag> : ''}
                                {parseInt(item.status) === 1 ? <Tag color="blue">Завершен</Tag> : <Tag color="purple">Не завершен</Tag>}
                            </div>
                            <div className="oep--content-element last" style={{ width: `${PERCENTAGE}%` }}>
                                {this._time(item.createdAt)}
                                <div className="arrow" onClick={() => openGame(item.id, item.number)}>
                                    <img src="/images/arrow.png" alt="Arrow" />
                                </div>
                            </div>
                        </div>
                    )}

                </div>

                <Pagination size={10} page={page} total={count} onPageChange={page => this.load(page, true)} />

            </div>
        )
    }

}

const mapStateToProps = state => {
    return {
        operators: state.operators
    }
}

export default connect(mapStateToProps)(OEPGames)