import React, { Component } from "react"

/* Redux */
import { connect } from 'react-redux'

/* Components */
import { Layout, OperatorFilter } from '../../components'

/* REST API */
import { tables } from '../../api/Game'

/* Router */
import { Link } from "react-router-dom"

/* Constants */
import { games } from '../../constants'

/* Helpers */
import { utils } from "../../helpers"



/* Page Games */
class Games extends Component {

    constructor() {
        super()

        this.state = {
            data: [],
            status: "loading",

            operators: [],
            cnt: 0
        }
    }

    componentDidMount = () => {
        this.load(false, true)
    }

    /* Load actions */
    load = (reload = false, initial = false) => {

        const { operators, cnt } = this.state

        let list = operators

        if (initial) {
            list = this.props.operators
            this.setState({ operators: list })
        }

        if (!list || !Array.isArray(list) || list.length < 1) {
            setTimeout(() => {
                this.setState({ operators: this.props.operators, cnt: cnt + 1 },
                    () => cnt < 5 ? this.load(false, initial)
                        : this.setState({ status: "permission", data: null, cnt: 0 })
                )
            }, 700)
            return
        }

        if (reload) {
            this.setState({ status: "loading" })
        }

        const ids = utils.arrayID(list)

        tables({ ids }).then(response => {
            if (response.status === 200) {
                this.setState({ status: "ok", data: response.data, cnt: 0 })
            }
            else if (response.status === 401 || response.status === 403) {
                this.setState({ status: "permission", data: [], cnt: 0 })
            }
            else if (response.status === 498) {
                this.setState({ status: "key", data: [], cnt: 0 })
            }
            else {
                this.setState({ status: "error", data: [], cnt: 0 })
            }
        }).catch(() => {
            this.setState({ status: "network", data: [], cnt: 0 })
        })
    }


    /* Get Filtered List */
    filtered = data => {

        let tables = [[], [], []]

        if (Array.isArray(data) && data.length > 0) {
            games.data.forEach(game => {

                // let list = []

                const i = data.findIndex(e => e.game === game.key)

                if (i !== -1) {

                    const tableIndex = games.gameSettings.findIndex(e => e.type === game.type)

                    if (tableIndex !== -1) {
                        tables[games.gameSettings[tableIndex].index].push({ ...data[i], type: game.type })
                    }

                }

                // data.forEach(table => {
                //     if (table.game === game.key) {
                //         list.push(table)
                //     }
                // })

                // data.forEach(table => {
                //     if (table.game === game.key) {
                //         list.push(table)
                //     }
                // })

                // tables.push({ name: game.name, type: game.type, games: list })
            })
        }

        return tables
    }


    render = () => {

        const { status, data, operators } = this.state

        const { admin } = this.props

        if (status !== "ok") {
            return <Layout status={status} reload={() => this.load(true)} />
        }

        const tables = this.filtered(data)

        return (
            <div className="page">

                <div className="page-header">
                    <h1>Игры</h1>

                    {
                        admin && admin.isAdmin ?
                            <Link to="/game/create" className="button">
                                <img src="/images/plus.png" alt="Plus" />
                                <span>Добавить</span>
                            </Link>
                            : null
                    }

                    {operators && operators.length > 0 ? <OperatorFilter mt={5} operators={operators} setOperators={list => this.setState({ operators: list })} apply={() => this.load(true)} /> : null}

                </div>

                {tables.map((item, index) => {

                    if (item.length === 0) {
                        return <div key={`${index}`} />
                    }

                    return (
                        <div className="games-box" key={`${index}`}>
                            <div className="games-title">{games.gameSettings[index].name}</div>
                            <div className="games">
                                {item.map((game, index) =>
                                    <Link to={`/table/${game.game}/${game.uid}`} className={`game ${game.type}`} key={`${index}`}>

                                        <div className="game-info">
                                            {game.type === "electron"
                                                ? <img src="/images/spades-white.png" alt="Spades" />
                                                : <img src="/images/spades.png" alt="Spades" />
                                            }

                                            <div className="game-text">
                                                <h2>{game.name}</h2>
                                                <span>{game.slug}</span>
                                            </div>
                                        </div>

                                        <div className={`game-available ${parseInt(game.visible) === 0 ? 'game-available-offline' : ''}`}>{parseInt(game.visible) === 1 ? "Включен" : "Отключен"}</div>

                                        <div className="game-button">
                                            Посмотреть
                                            <img src="/images/right-arrow-white.png" alt="Arrow" />
                                        </div>
                                    </Link>
                                )}
                            </div>
                        </div>
                    )
                })}

            </div>
        )
    }
}


const mapStateToProps = state => {
    return {
        admin: state.admin,
        operators: state.operators
    }
}

export default connect(mapStateToProps)(Games)