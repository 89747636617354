import React, { Component } from "react"

/* Components */
import { Avatar, Layout, OperatorFilter } from '../../components'

/* Helpers */
import { utils, datetime } from '../../helpers'

/* Modal */
import Modal from 'react-modal'

/* Redux */
import { connect } from 'react-redux'

/* REST API */
import { all } from '../../api/Dashboard'

/* Moment */
import moment from 'moment'
import 'moment/locale/ru'

/* DatePicker */
import DatePicker, { registerLocale } from 'react-datepicker'
import ru from "date-fns/locale/ru"

/* Widgets */
import { REPStatistics } from '../../widgets/REP'
import { OEPStatistics } from '../../widgets/OEP'
import { QEPStatistics } from '../../widgets/QEP'
import { UEPStatistics } from '../../widgets/UEP'
import { EEPStatistics } from '../../widgets/EEP'
import { HEPStatistics } from '../../widgets/HEP'
import { R2EPStatistics } from '../../widgets/R2EP'
import { SEPStatistics } from '../../widgets/SEP'
import { NEPStatistics } from '../../widgets/NEP'
import { JEPStatistics } from '../../widgets/JEP'


/* Export Excel */
import { downloadExcel } from "react-export-table-to-excel"
import { downloadExcel as downloadXlsx } from 'react-export-table-to-excel-xlsx'

/* DatePicker Settings */
registerLocale("ru", ru)

/* Moment Settings */
moment.locale("ru")

/* Fields */
const DATES = [
    { key: "all", text: "За все время" },
    { key: "today", text: "За сегодня" },
    { key: "week", text: "За неделю" },
    { key: "month", text: "За месяц" },
    { key: "year", text: "За год" },
    { key: "period", text: "Период" },
]

const GAME_TYPES = [
    { key: "electron", text: "Electron" },
    { key: "multi", text: "Live" },
    { key: "vip", text: "VIP" },
]


/* Page Dashboard */
class Dashboard extends Component {

    constructor() {
        super()

        const date = utils.getToday()

        this.state = {
            status: "loading",
            data: null,

            operators: [],

            filter: false,
            selected: "today",
            active: "today",
            start: date.startDate,
            end: date.endDate,
            type: "electron",

            games: [],
            waiting: false,

            statistics: false,
            game: null,
            table: null,

            cnt: 0,
        }
    }

    componentDidMount = () => {
        this.load(false, true)
    }

    /* Load action */
    load = (reload = false, initial = false) => {

        const { active, start, end, operators, cnt } = this.state

        let list = operators

        if (initial) {
            list = this.props.operators
            this.setState({ operators: list })
        }

        if (!list || !Array.isArray(list) || list.length < 1) {
            setTimeout(() => {
                this.setState({ operators: this.props.operators, cnt: cnt + 1 },
                    () => cnt < 5 ? this.load(false, initial)
                        : this.setState({ status: "permission", data: null, cnt: 0 })
                )
            }, 700)
            return
        }

        if (reload) {
            this.setState({ status: "loading", cnt: 0 })
        }

        all({ ids: utils.arrayID(list), filter: active, start, end }).then(response => {
            if (response.status === 200) {
                this.setState({ status: "ok", data: response.data, games: response.data.games, cnt: 0 })
            }
            else if (response.status === 401 || response.status === 403) {
                this.setState({ status: "permission", data: null, cnt: 0 })
            }
            else if (response.status === 498) {
                this.setState({ status: "key", data: null, cnt: 0 })
            }
            else {
                this.setState({ status: "error", data: null, cnt: 0 })
            }
        }).catch(() => {
            this.setState({ status: "network", data: null, cnt: 0 })
        })

    }

    /* Apply action */
    apply = () => {

        const { selected } = this.state

        if (selected === "all" || selected === "period") {
            this.setState({ active: selected, filter: false }, () => this.load(true))
        }
        else {
            const { start, end } = datetime.getDashboardFilterDates(selected)
            this.setState({ active: selected, start, end, filter: false }, () => this.load(true))
        }
    }

    /* Get Filter Date */
    getFilterDate = () => {

        const { active, start, end } = this.state

        const index = DATES.findIndex(e => e.key === active)

        if (index > -1) {

            let text = `${moment(start).format("DD MMM 'YY")} - ${moment(end).format("DD MMM 'YY")}`

            if (active === "all") {
                text = ""
            }

            return { filter: DATES[index].text, text }
        }

        return { filter: DATES[0].text, text: "" }
    }

    /* Download Excel */
    downloadExcel = (type) => {

        /* FIELDS */
        const { games, data } = this.state

        const downloadFunc = type === "xls" ? downloadExcel : downloadXlsx

        /* CHECK DATA */
        if (data && games && Array.isArray(games) && games.length > 0) {

            /* TABLE FIELDS */
            const filtered = this.getFilterDate()
            const title = `Отчет по играм ${String(filtered.filter).toUpperCase()} (${filtered.text})`

            const header = {
                name: "Название игры",
                players: "Количество игроков",
                rounds: "Количество раундов",
                ante: "Количество ставки ANTE",
                debit: "Ставка (DEBIT)",
                credit: "Выплата (CREDIT)",
                total: "Прибыль (TOTAL)"
            }

            let body = [{ name: title }, { name: '' }, header]

            games.forEach(game => {
                body.push({
                    name: utils.checkValue(game.name, "-"),
                    players: utils.checkValue(game.players, "0"),
                    rounds: utils.checkValue(game.rounds, "0"),
                    ante: utils.checkValue(game.ante, "0"),
                    debit: utils.convertor(parseFloat(Math.abs(game.debit)), "$", 0, " "),
                    credit: utils.convertor(parseFloat(Math.abs(game.credit)), "$", 0, " "),
                    total: `${game.total >= 0 ? "+" : "-"} ${utils.convertor(parseFloat(Math.abs(game.total)), "$", 0, " ")}`,
                })
            })

            body.push({ name: "" })

            body.push({
                name: "Итого",
                players: utils.checkValue(data.playerList.length, "0"),
                rounds: utils.checkValue(data.rounds, "0"),
                ante: utils.checkValue(data.ante, "0"),
                debit: utils.convertor(parseFloat(Math.abs(data.debit)), "$", 0, " "),
                credit: utils.convertor(parseFloat(Math.abs(data.credit)), "$", 0, " "),
                total: `${data.total >= 0 ? "+" : "-"} ${utils.convertor(parseFloat(Math.abs(data.total)), "$", 0, " ")}`,
            })

            downloadFunc({
                fileName: `Отчет по играм ${String(filtered.filter).toUpperCase()}`,
                sheet: "Отчет по играм",
                tablePayload: { header: [], body },
            })
        }
    }

    /* Set a period value */
    setDate = (data, key) => {
        if (key === "start") {
            this.setState({ start: data })
        } else {
            this.setState({ end: data })
        }
    }

    /* Draw Header */
    _header = () => {

        const { operators } = this.state

        const json = localStorage.getItem("user")
        const user = json ? JSON.parse(json) : null
        const date = this.getFilterDate()

        return (
            <div className="dashboard-header">

                <div className="dashboard-header-user">
                    <div className="dashboard-header-text">Привет, </div>
                    {user !== null && user !== undefined && <div className="dashboard-header-title">{user.name}</div>}
                </div>

                <div className="dashboard-header-filter">
                    <div onClick={() => this.setState({ filter: true })} className="dashboard-filter">
                        <img src="/dashboard/calendar.png" alt="Calendar" />
                        <div className="dashboard-filter-text">
                            <div className="dashboard-filter-text-type">{date.filter}</div>
                            <div className="dashboard-filter-text-value">{date.text}</div>
                        </div>
                    </div>

                    <div onClick={() => this.downloadExcel("xls")} className="dashboard-xls-button">.XLS</div>
                    <div onClick={() => this.downloadExcel("xlsx")} className="dashboard-xls-button">.XLSX</div>
                </div>

                {operators && operators.length > 0 ? <OperatorFilter operators={operators} setOperators={list => this.setState({ operators: list })} apply={() => this.load(true)} /> : null}

            </div>
        )
    }

    /* Draw Filter Modal */
    _filter = () => {

        /* Fields */
        const { filter, start, end, selected, active } = this.state

        return (
            <Modal
                isOpen={filter}
                onRequestClose={() => this.setState({ filter: false, selected: active })}
                className={`dashboard-filter-modal ${selected === "period" ? "period" : ""}`}
                overlayClassName="dashboard-filter-overlay"
                closeTimeoutMS={200}
            >

                <div className="dashboard-filter-header">
                    <h2>Выберите дату</h2>
                    <div onClick={() => this.setState({ filter: false, selected: active })} className="dashboard-filter-close">
                        <img src="/images/close.png" alt="Close" />
                    </div>
                </div>

                <div className="dashboard-filter-container">

                    <div className="dashboard-filter-container-inner">

                        {/* Dates */}
                        <div className="dashboard-filter-dates">
                            {DATES.map((item, index) =>
                                <div onClick={() => this.setState({ selected: item.key })} className={`dashboard-filter-date ${item.key === selected ? 'active' : 'default'}`} key={`${index}`}>{item.text}</div>
                            )}
                        </div>

                        {/* Period */}
                        {selected === "period" &&
                            <div className="dashboard-filter-period-container">

                                <div className="dashboard-filter-period-title">Выберите даты</div>

                                <div className="dashboard-filter-period">

                                    <DatePicker
                                        ref={ref => this._start = ref}
                                        selected={start}
                                        onChange={date => this.setDate(date, "start")}
                                        selectsStart
                                        startDate={start}
                                        endDate={end}
                                        dateFormat="dd-MM-yyyy"
                                        locale="ru"
                                    />

                                    <span>-</span>

                                    <DatePicker
                                        selected={end}
                                        onChange={date => this.setDate(date, "end")}
                                        selectsEnd
                                        startDate={start}
                                        endDate={end}
                                        minDate={start}
                                        dateFormat="dd-MM-yyyy"
                                        locale="ru"
                                    />
                                </div>
                            </div>
                        }

                    </div>

                    {/* Bottom */}
                    <div className="dashboard-filter-bottom">
                        <div onClick={() => this.setState({ filter: false, selected: active })} className="dashboard-filter-button cancel">Отменить</div>
                        <div onClick={() => this.apply()} className="dashboard-filter-button">Применить</div>
                    </div>

                </div>

            </Modal>
        )
    }

    /* Draw Popular Widget */
    _popular = (item, index) => {

        return (
            <div onClick={() => this.setState({ statistics: true, game: item.key, table: item.table ? item.table : null })} className="dashboard-popular-widget" key={`${index}`}>

                {item.type === "electron" && <img src="/games/rep.webp" alt="REP" />}
                {(item.type === "vip" || item.type === "multi") && <img src="/games/back-casino.webp" alt="REP" style={{ opacity: 0.3 }} />}

                <div className="dashboard-popular-widget-head">

                    <div className="dashboard-popular-widget-tags">

                        <div className="dashboard-popular-widget-tag">
                            <img src="/dashboard/fire.png" alt="Fire" />
                            <div className="dashboard-popular-widget-tag-text">Popular</div>
                        </div>

                        {item.type === "electron" &&
                            <div className="dashboard-game-widget-tag pink">
                                <img src="/dashboard/robot.png" alt="Fire" />
                                <div className="dashboard-game-widget-tag-text">Electron</div>
                            </div>
                        }

                        {(item.type === "multi" || item.type === "vip") &&
                            <div className="dashboard-game-widget-tag red">
                                <img src="/dashboard/stream.png" alt="Fire" />
                                <div className="dashboard-game-widget-tag-text">Live</div>
                            </div>
                        }

                        {item.type === "vip" &&
                            <div className="dashboard-game-widget-tag yellow">
                                <img src="/dashboard/premium.png" alt="Fire" />
                                <div className="dashboard-game-widget-tag-text">VIP</div>
                            </div>
                        }

                    </div>

                    <div className="dashboard-popular-widget-players">

                        {item.players >= 1 &&
                            <div className="dashboard-popular-widget-player">
                                <Avatar uri="/images/player/gamer.png" size={30} />
                            </div>
                        }
                        {item.players >= 2 &&
                            <div className="dashboard-popular-widget-player">
                                <Avatar uri="/images/player/gamer.png" size={30} />
                            </div>
                        }
                        {item.players >= 3 &&
                            <div className="dashboard-popular-widget-player">
                                <Avatar uri="/images/player/gamer2.png" size={30} />
                            </div>
                        }

                        <div className="dashboard-popular-widget-player-count">{item.players > 3 ? `+ ${item.players - 3}` : item.players} {item.players > 4 ? 'игроков' : item.players === 1 ? 'игрок' : 'игрока'}</div>
                    </div>

                </div>

                <div className="dashboard-popular-widget-title">{item.name}</div>

                <div className="dashboard-popular-widget-numbers">

                    <div className="dashboard-popular-widget-box">
                        <img src="/dashboard/increase.png" alt="Increase" />
                        <div className="dashboard-popular-widget-box-text">
                            <div className="dashboard-popular-widget-box-key">Debit <img src="/images/similar.png" alt="Calculate" /></div>
                            <div className="dashboard-popular-widget-box-value">+ {utils.convertor(parseFloat(Math.abs(item.debit)), "$", 0, " ")}</div>
                        </div>
                    </div>

                    <div className="dashboard-popular-widget-box">
                        <img src="/dashboard/decrease.png" alt="Decrease" />
                        <div className="dashboard-popular-widget-box-text">
                            <div className="dashboard-popular-widget-box-key">Credit <img src="/images/similar.png" alt="Calculate" /></div>
                            <div className="dashboard-popular-widget-box-value">- {utils.convertor(parseFloat(Math.abs(item.credit)), "$", 0, " ")}</div>
                        </div>
                    </div>

                    <div className="dashboard-popular-widget-box">
                        <img src="/dashboard/calculate.png" alt="Increase" />
                        <div className="dashboard-popular-widget-box-text">
                            <div className="dashboard-popular-widget-box-key">Прибыль (GGR) <img src="/images/similar.png" alt="Calculate" /></div>
                            <div className="dashboard-popular-widget-box-value">
                                {item.total >= 0 ? "+ " : "- "}
                                {utils.convertor(parseFloat(Math.abs(item.total)), "$", 0, " ")}
                            </div>
                        </div>
                    </div>

                    <div className="dashboard-popular-widget-box">
                        <img src="/dashboard/chip.png" alt="Chip" />
                        <div className="dashboard-popular-widget-box-text">
                            <div className="dashboard-popular-widget-box-key">Ставка ANTE</div>
                            <div className="dashboard-popular-widget-box-value">{utils.checkValue(item.ante, "0")}</div>
                        </div>
                    </div>

                    <div className="dashboard-popular-widget-box">
                        <img src="/dashboard/cards.png" alt="Cards" />
                        <div className="dashboard-popular-widget-box-text">
                            <div className="dashboard-popular-widget-box-key">Раунды</div>
                            <div className="dashboard-popular-widget-box-value">{utils.checkValue(item.rounds, "0")}</div>
                        </div>
                    </div>

                    <div className="dashboard-popular-widget-box">
                        <img src="/dashboard/percent.png" alt="Percent" />
                        <div className="dashboard-popular-widget-box-text">
                            <div className="dashboard-popular-widget-box-key">PROFIT</div>
                            {Number(item.total / item.debit * 100) && Math.abs(Number(item.total / item.debit * 100)) > 0
                                ? <div className="dashboard-popular-widget-box-value">{Number(item.total / item.debit * 100).toFixed(0)} %</div>
                                : <div className="dashboard-popular-widget-box-value">-</div>
                            }
                        </div>
                    </div>

                </div>

            </div>
        )
    }

    /* TOP WIDGET */
    _top = () => {

        const { data } = this.state

        const percentage = Number(data.total / data.debit * 100).toFixed(0)

        return (
            <div className="dashboard-top-widget">

                {/* BATTERY WIDGET */}
                <div className="dashboard-battery-widget">

                    <div className="dashboard-battery-widget-container">
                        <div className="dashboard-battery-widget-layout">
                            {percentage && Math.abs(percentage) > 0 &&
                                <div className={`dashboard-battery-widget-container-inner ${percentage > 0 ? 'green' : 'red'}`} style={{ width: `${Math.abs(percentage)}%` }}>
                                    <div className="dashboard-battery-widget-container-text">{percentage}%</div>
                                </div>
                            }
                        </div>
                    </div>

                    <div className="dashboard-battery-numbers">

                        <div className="dashboard-battery-widget-box">
                            <img src="/dashboard/increase.png" alt="Increase" />
                            <div className="dashboard-battery-widget-box-text">
                                <div className="dashboard-battery-widget-box-key">Debit <img src="/images/similar.png" alt="Calculate" /></div>
                                <div className="dashboard-battery-widget-box-value">+ {utils.convertor(parseFloat(Math.abs(data.debit)), "$", 0, " ")}</div>
                            </div>
                        </div>

                        <div className="dashboard-battery-widget-box">
                            <img src="/dashboard/decrease.png" alt="Decrease" />
                            <div className="dashboard-battery-widget-box-text">
                                <div className="dashboard-battery-widget-box-key">Credit <img src="/images/similar.png" alt="Calculate" /></div>
                                <div className="dashboard-battery-widget-box-value">- {utils.convertor(parseFloat(Math.abs(data.credit)), "$", 0, " ")}</div>
                            </div>
                        </div>

                        <div className="dashboard-battery-widget-box">
                            <img src="/dashboard/calculate.png" alt="Increase" />
                            <div className="dashboard-battery-widget-box-text">
                                <div className="dashboard-battery-widget-box-key">Прибыль (GGR) <img src="/images/similar.png" alt="Calculate" /></div>
                                <div className={`dashboard-battery-widget-box-value ${data.total >= 0 ? 'green' : 'red'}`}>
                                    {data.total >= 0 ? "+ " : "- "}
                                    {utils.convertor(parseFloat(Math.abs(data.total)), "$", 0, " ")}
                                </div>
                            </div>
                        </div>

                    </div>

                </div>

                <div className="dashboard-top-numbers-widget">

                    {/* ANGLE WIDGET */}
                    <div className="dashboard-top-angle-widget">
                        <div className="dashboard-top-angle-widget-box">
                            <div className="dashboard-top-angle-widget-text">Посетители</div>
                            <div className="dashboard-top-angle-widget-value">{utils.checkValue(data.players, "-")}</div>
                        </div>
                        <div className="dashboard-top-angle-widget-box">
                            <div className="dashboard-top-angle-widget-text">Игроки</div>
                            <div className="dashboard-top-angle-widget-value">{utils.checkValue(data.playerList.length, "-")}</div>
                        </div>
                        <div className="dashboard-top-angle-widget-box">
                            <div className="dashboard-top-angle-widget-text">Раунды</div>
                            <div className="dashboard-top-angle-widget-value">{utils.checkValue(data.rounds, "-")}</div>
                        </div>
                        <div className="dashboard-top-angle-widget-box">
                            <div className="dashboard-top-angle-widget-text">Ставка ANTE</div>
                            <div className="dashboard-top-angle-widget-value">{utils.checkValue(data.ante, "-")}</div>
                        </div>
                        <div className="dashboard-top-angle-widget-box">
                            <div className="dashboard-top-angle-widget-text">Игры</div>
                            <div className="dashboard-top-angle-widget-value">{utils.checkValue(data.tables, "-")}</div>
                        </div>
                    </div>

                    {/* INSTRUMENTS WIDGET */}
                    <div className="dashboard-top-instuments-widget">

                        <div className="dashboard-top-instument-note">
                            <img src="/images/game/writing.png" alt="Note" />
                            <div className="dashboard-top-instument-text">Заметки</div>
                        </div>

                        <div className="dashboard-top-instument-activity">
                            <img src="/images/activity.png" alt="Activity" />
                            <div className="dashboard-top-instument-text">Активность</div>
                        </div>

                    </div>

                </div>

            </div >
        )
    }


    /* Draw Game */
    _game = (item, index) => {

        return (
            <div onClick={() => this.setState({ statistics: true, game: item.key, table: item.table ? item.table : null })} className={`dashboard-game-widget ${item.color}`} key={`${index}`}>

                {item.type === "electron" && <img src="/games/rep.webp" alt="REP" />}
                {(item.type === "vip" || item.type === "multi") && <img src="/games/back-casino.webp" alt="REP" style={{ opacity: 0.3 }} />}

                <div className="dashboard-game-widget-head">

                    <div className="dashboard-game-widget-tags">

                        {item.type === "electron" &&
                            <div className="dashboard-game-widget-tag pink">
                                <img src="/dashboard/robot.png" alt="Fire" />
                                <div className="dashboard-game-widget-tag-text">Electron</div>
                            </div>
                        }

                        {(item.type === "multi" || item.type === "vip") &&
                            <div className="dashboard-game-widget-tag red">
                                <img src="/dashboard/stream.png" alt="Fire" />
                                <div className="dashboard-game-widget-tag-text">Live</div>
                            </div>
                        }

                        {item.type === "vip" &&
                            <div className="dashboard-game-widget-tag yellow">
                                <img src="/dashboard/premium.png" alt="Fire" />
                                <div className="dashboard-game-widget-tag-text">VIP</div>
                            </div>
                        }

                    </div>

                    <div className="dashboard-game-widget-players">
                        <div className="dashboard-game-widget-player-count">{item.players} {item.players > 4 ? 'игроков' : item.players === 1 ? 'игрок' : 'игрока'}</div>
                    </div>

                </div>

                <div className="dashboard-game-widget-title">{item.name}</div>

                <div className="dashboard-game-widget-numbers">

                    <div className="dashboard-game-widget-box">
                        <img src="/dashboard/increase.png" alt="Increase" />
                        <div className="dashboard-game-widget-box-text">
                            <div className="dashboard-game-widget-box-key">Debit <img src="/images/similar.png" alt="Calculate" /></div>
                            <div className="dashboard-game-widget-box-value">+ {utils.convertor(parseFloat(Math.abs(item.debit)), "$", 0, " ")}</div>
                        </div>
                    </div>

                    <div className="dashboard-game-widget-box">
                        <img src="/dashboard/decrease.png" alt="Decrease" />
                        <div className="dashboard-game-widget-box-text">
                            <div className="dashboard-game-widget-box-key">Credit <img src="/images/similar.png" alt="Calculate" /></div>
                            <div className="dashboard-game-widget-box-value">- {utils.convertor(parseFloat(Math.abs(item.credit)), "$", 0, " ")}</div>
                        </div>
                    </div>

                    <div className="dashboard-game-widget-box">
                        <img src="/dashboard/calculate.png" alt="Increase" />
                        <div className="dashboard-game-widget-box-text">
                            <div className="dashboard-game-widget-box-key">Прибыль (GGR) <img src="/images/similar.png" alt="Calculate" /></div>
                            <div className="dashboard-game-widget-box-value">
                                {item.total >= 0 ? "+ " : "- "}
                                {utils.convertor(parseFloat(Math.abs(item.total)), "$", 0, " ")}
                            </div>
                        </div>
                    </div>

                    <div className="dashboard-game-widget-box">
                        <img src="/dashboard/chip.png" alt="Chip" />
                        <div className="dashboard-game-widget-box-text">
                            <div className="dashboard-game-widget-box-key">Ставка ANTE</div>
                            <div className="dashboard-game-widget-box-value">{utils.checkValue(item.ante, "0")}</div>
                        </div>
                    </div>

                    <div className="dashboard-game-widget-box">
                        <img src="/dashboard/cards.png" alt="Cards" />
                        <div className="dashboard-game-widget-box-text">
                            <div className="dashboard-game-widget-box-key">Раунды</div>
                            <div className="dashboard-game-widget-box-value">{utils.checkValue(item.rounds, "0")}</div>
                        </div>
                    </div>

                    <div className="dashboard-game-widget-box">
                        <img src="/dashboard/percent.png" alt="Percent" />
                        <div className="dashboard-game-widget-box-text">
                            <div className="dashboard-game-widget-box-key">PROFIT</div>
                            {Number(item.total / item.debit * 100) && Math.abs(Number(item.total / item.debit * 100)) > 0
                                ? <div className="dashboard-game-widget-box-value">{Number(item.total / item.debit * 100).toFixed(0)} %</div>
                                : <div className="dashboard-game-widget-box-value">-</div>
                            }
                        </div>
                    </div>

                </div>

            </div>
        )
    }

    /* Draw Games */
    _games = () => {

        const { games, type } = this.state

        let list = []
        games.forEach(item => {
            if (item.type === type) {
                list.push(item)
            }
        })

        list.sort((a, b) => {

            if (a.players > b.players) {
                return -1
            }

            if (a.players < b.players) {
                return 1
            }

            return 0
        })

        return (
            <div className="dashboard-games-container">
                {list.map((item, index) => {
                    if (index === 0)
                        return this._popular(item, index)
                    return this._game(item, index)
                })}
            </div>
        )
    }


    /* Draw Modal */
    _modal = () => {

        const { statistics, game, table } = this.state

        let content = <p className="dashboard-empty-modal-text">Статистика не доступа</p>

        if (table && game) {

            if (game === "russian-electron-poker") {
                content = <REPStatistics uid={table.uid} />
            }
            if (game === "open-electron-poker") {
                content = <OEPStatistics uid={table.uid} />
            }
            if (game === "queen-electron-poker") {
                content = <QEPStatistics uid={table.uid} />
            }
            if (game === "ultimate-electron-poker") {
                content = <UEPStatistics uid={table.uid} />
            }
            if (game === "european-electron-poker") {
                content = <EEPStatistics uid={table.uid} />
            }
            if (game === "casino-holdem-electron-poker") {
                content = <HEPStatistics uid={table.uid} />
            }
            if (game === "russian-two-box-electron-poker") {
                content = <R2EPStatistics uid={table.uid} />
            }
            if (game === "super-electron-poker") {
                content = <SEPStatistics uid={table.uid} />
            }
            if (game === "nova-electron-poker") {
                content = <NEPStatistics uid={table.uid} />
            }
            if (game === "j-electron-poker") {
                content = <JEPStatistics uid={table.uid} />
            }
        }

        return (
            <Modal
                isOpen={statistics}
                onRequestClose={() => this.setState({ statistics: false, game: null, table: null })}
                className="rep--modal rep--modal-statistics"
                overlayClassName="rep--overlay rep--overlay-statistics"
                closeTimeoutMS={200}
            >

                <div className="rep--modal-header">
                    <h2>Статистика</h2>
                    <div onClick={() => this.setState({ statistics: false, game: null, table: null })} className="rep--modal-close">
                        <img src="/images/close.png" alt="Close" />
                    </div>
                </div>

                {content}

            </Modal>
        )
    }


    render = () => {

        /* Fields */
        const { type, status } = this.state

        /* Layout */
        if (status !== "ok") {
            return <Layout status={status} reload={() => this.load(true)} />
        }

        return (
            <div className="page">

                {/* HEADER */}
                {this._header()}

                <div className="dashboard-container">

                    {/* TOP WIDGETS */}
                    {this._top()}

                    {/* Games */}
                    <div className="dashboard-games">

                        {/* GAME HEADER */}
                        <div className="dashboard-games-header">
                            <h2>Игры</h2>
                            <div className="dashboard-games-header-types">
                                {GAME_TYPES.map((item, index) =>
                                    <div onClick={() => this.setState({ type: item.key })} className={`dashboard-games-header-type ${item.key === type ? 'active' : 'default'}`} key={`${index}`}>{item.text}</div>
                                )}
                            </div>
                        </div>

                        {/* GAMES */}
                        {this._games()}

                    </div>

                </div>

                {/* FILTER MODAL */}
                {this._filter()}

                {/* STATISTICS MODAL */}
                {this._modal()}

            </div>
        )
    }

}

const mapStateToProps = state => {
    return {
        operators: state.operators
    }
}

export default connect(mapStateToProps)(Dashboard)