import React, { Component } from "react"

/* Components */
import { InnerLayout, Avatar, OperatorFilter } from '../../components'

/* REST API */
import { statistics, information, periodStatistics } from '../../api/Player'

/* Chart */
import { AreaChart, XAxis, YAxis, CartesianGrid, Tooltip, Area, ResponsiveContainer } from 'recharts'

/* Helpers */
import { utils, datetime } from "../../helpers"

/* Games */
import { games } from '../../constants'

/* Icons */
import { LoadingOutlined } from '@ant-design/icons'

/* Moment */
import moment from 'moment'
import 'moment/locale/ru'

/* Moment Settings */
moment.locale("ru")



/* Widget Information */
class Information extends Component {

    constructor(props) {
        super(props)

        const date = datetime.getStatisticsDate("day")

        this.state = {
            information: null,
            status: "loading",
            tab: "",
            ava: "",

            game: null,
            gameStatus: "loading",

            filter: "day",
            start: date.start,
            end: date.end,
            statistics: null,
            chart: [],
            waiting: false,

            operators: props.operators
        }
    }

    componentDidMount = () => {
        this.load()
    }

    setOperators = (list) => {
        this.setState({ operators: list }, () => this.load(true))
    }

    /* Load action */
    load = (reload = false) => {

        const { player } = this.props

        const { operators } = this.state

        /* Reload Page */
        if (reload) {
            this.setState({ status: 'loading' })
        }

        const ids = utils.arrayID(operators)

        /* Send to REST API */
        statistics({ player, ids }).then(response => {
            if (response.status === 200) {

                const information = response.data
                const ava = utils.playerAva(information.information.isTester)
                this.setState({ status: "ok", information, ava })

                if (information.data && Array.isArray(information.data) && information.data.length > 0) {
                    this.gameLoad(information.data[0].game)
                }

                console.log(information)
            }
            else if (response.status === 401 || response.status === 403) {
                this.setState({ status: "permission", information: null })
            }
            else if (response.status === 498) {
                this.setState({ status: "key", information: null })
            }
            else {
                this.setState({ status: "error", information: null })
            }
        }).catch(() => {
            this.setState({ status: "network", information: null })
        })

    }

    /* Get Game Text */
    getGame = key => {
        const index = games.data.findIndex(e => e.key === key)
        if (index > -1) {
            return games.data[index].name
        }
    }


    /* Load By Game */
    gameLoad = game => {

        /* Fields */
        const { player, scrollToTop, operators } = this.props

        /* Save Game */
        this.setState({ tab: game, game: null, gameStatus: 'loading' })

        /* Scroll to top */
        scrollToTop()

        /* Send to REST API */
        information({ player, game, ids: utils.arrayID(operators) }).then(response => {
            if (response.status === 200) {
                this.setState({ gameStatus: "ok", game: response.data })
                const date = datetime.getStatisticsDate("day")
                this.setState({ filter: "day", start: date.start, end: date.end }, () => this.getStatistics())
            }
            else if (response.status === 401 || response.status === 403) {
                this.setState({ gameStatus: "permission" })
            }
            else if (response.status === 498) {
                this.setState({ gameStatus: "key" })
            }
            else {
                this.setState({ gameStatus: "error" })
            }
        }).catch(() => {
            this.setState({ gameStatus: "network" })
        })
    }


    /* Draw Combinations */
    _combinations = combinations => {

        if (combinations && Array.isArray(combinations) && combinations.length > 0) {
            return (
                <div className="player-information-combinations">
                    <h3>Комбинации</h3>

                    <div className="player-information-combination-container">
                        {combinations.map((combination, index) =>
                            <div className="player-information-combination" key={`${index}`}>
                                <div className="player-information-combination-name">{combination.result}</div>
                                <div className="player-information-combination-value">{combination.count}</div>
                            </div>
                        )}
                    </div>
                </div>
            )
        }

    }



    /* DRAW INFORMATION WIDGET */
    _information = () => {

        const { information, ava } = this.state

        return (
            <div className="player-information-widget" >

                {/* Player */}
                <div className="player-information-widget-user" >
                    <Avatar uri={ava} size={40} />
                    <div className="player-information-widget-user-text">
                        <div className="player-information-widget-user-code">{parseInt(information.information.isTester) > 0 ? "Тестировщик" : "Игрок"}</div>
                        <div className="player-information-widget-user-name">{utils.checkValue(information.information.firstName, "-")}</div>
                        <div className="player-information-widget-user-code">{utils.checkValue(information.information.player, "-")}</div>
                    </div>
                </div>

                {/* Games Count */}
                <div className="player-information-widget-element" >
                    <img src="/images/player/spades.png" alt="Spades" />
                    <div className="player-information-widget-element-text">
                        <div className="player-information-widget-element-name">Количество игр</div>
                        <div className="player-information-widget-element-value">{utils.checkValue(information.count, "0")}</div>
                    </div>
                </div>

                {/* AVG ANTE */}
                <div className="player-information-widget-element" >
                    <img src="/images/player/poker-chip.png" alt="Poker Chip" />
                    <div className="player-information-widget-element-text">
                        <div className="player-information-widget-element-name">Средняя ставка ANTE</div>
                        <div className="player-information-widget-element-value">{utils.convertor(parseInt(information.ante), utils.checkValue(information.information.symbol, "$"), utils.checkValue(information.information.isAfter, 0), " ")}</div>
                    </div>
                </div>

                {/* Total Time */}
                <div className="player-information-widget-element" >
                    <img src="/images/player/back-in-time.png" alt="Time" />
                    <div className="player-information-widget-element-text">
                        <div className="player-information-widget-element-name">Общее время</div>
                        <div className="player-information-widget-element-value">{utils.converTime(information.time)}</div>
                    </div>
                </div>

                {/* Debit */}
                <div className="player-information-widget-element" >
                    <img src="/images/player/increase.png" alt="Increase" />
                    <div className="player-information-widget-element-text">
                        <div className="player-information-widget-element-name">Debit</div>
                        <div className="player-information-widget-element-value">{utils.convertor(information.debit, utils.checkValue(information.information.symbol, "$"), utils.checkValue(information.information.isAfter, 0), " ")}</div>
                    </div>
                </div>

                {/* Credit */}
                <div className="player-information-widget-element" >
                    <img src="/images/player/decrease.png" alt="Decrease" />
                    <div className="player-information-widget-element-text">
                        <div className="player-information-widget-element-name">Credit</div>
                        <div className="player-information-widget-element-value">{utils.convertor(information.credit, utils.checkValue(information.information.symbol, "$"), utils.checkValue(information.information.isAfter, 0), " ")}</div>
                    </div>
                </div>

                {/* Total */}
                <div className="player-information-widget-element" >
                    <img src="/images/player/calculate.png" alt="Calculate" />
                    <div className="player-information-widget-element-text">
                        <div className="player-information-widget-element-name">Общая сумма</div>
                        <div className={`player-information-widget-element-value ${information.debit - information.credit >= 0 ? 'green' : 'red'}`}>{information.debit - information.credit >= 0 ? "+" : "-"} {utils.convertor(Math.abs(information.debit - information.credit), utils.checkValue(information.information.symbol, "$"), utils.checkValue(information.information.isAfter, 0), " ")}</div>
                    </div>
                </div>

            </div>
        )
    }


    /* DRAW TABS */
    _tabs = () => {

        /* FIELDS */
        const { information, tab } = this.state

        return (
            <div className="player-information-games-tabs">
                {information.data.map((item, index) =>
                    <div onClick={() => this.gameLoad(item.game)} className={`player-information-games-tab ${tab === item.game ? 'active' : 'disabled'}`} key={`${index}`}>

                        <div className="player-information-games-tab-name">{this.getGame(item.game)}</div>

                        <div className="player-information-games-tab-row">
                            <div className="player-information-games-tab-inner">
                                <img src="/images/player/increase.png" alt="Increase" />
                                <div className="player-information-games-tab-value">+ {utils.convertor(item.debit, utils.checkValue(information.information.symbol, "$"), utils.checkValue(information.information.isAfter, 0), " ")}</div>
                            </div>
                            <div className="player-information-games-tab-inner">
                                <img src="/images/player/decrease.png" alt="Decrease" />
                                <div className="player-information-games-tab-value">- {utils.convertor(item.credit, utils.checkValue(information.information.symbol, "$"), utils.checkValue(information.information.isAfter, 0), " ")}</div>
                            </div>
                        </div>

                        <div className="player-information-games-tab-inner">
                            <img src="/images/player/calculate.png" alt="Calculate" />
                            <div className={`player-information-games-tab-value ${item.debit - item.credit >= 0 ? 'green' : 'red'}`}>
                                {item.debit - item.credit >= 0 ? "+ " : "- "}
                                {utils.convertor(Math.abs(item.debit - item.credit), utils.checkValue(information.information.symbol, "$"), utils.checkValue(information.information.isAfter, 0), " ")}
                            </div>
                        </div>

                    </div>
                )}
            </div>
        )
    }


    /* Load Statistics Date */
    getStatistics = () => {

        /* Fields */
        const { player } = this.props
        const { filter, start, end, tab, operators } = this.state

        /* Start Loading */
        this.setState({ waiting: true, chart: [], statistics: null })

        /* Get Generated Dates */
        const dates = datetime.generateDates(filter, start, end)

        /* Load Statistics */
        periodStatistics({ player, ids: utils.arrayID(operators), game: tab, start, end, dates }).then(response => {
            if (response.status === 200) {
                this.setState({ waiting: false, statistics: response.data.statistics, chart: response.data.chart })
            }
        })
    }


    /* Set Filter */
    setFilter = type => {

        const { filter } = this.state

        if (filter !== type) {
            const date = datetime.getStatisticsDate(type)
            this.setState({ filter: type, start: date.start, end: date.end }, () => this.getStatistics())
        }
    }

    /* Go action */
    go = type => {

        const { filter, start, end } = this.state
        const date = datetime.setStatisticsDate(type, filter, start, end)

        if (date) {
            this.setState({ start: date.start, end: date.end }, () => this.getStatistics())
        }

    }

    /* Type Formatter */
    formatter = (value, name) => {

        const { information } = this.state

        if (name === "debit") {
            return [<div className="similar-text">{utils.convertor(value, utils.checkValue(information.information.symbol, "$"), utils.checkValue(information.information.isAfter, 0), " ")}</div>, "DEBIT"]
        }

        if (name === "credit") {
            return [<div className="similar-text">{utils.convertor(value, utils.checkValue(information.information.symbol, "$"), utils.checkValue(information.information.isAfter, 0), " ")}</div>, "CREDIT"]
        }

    }


    /* DRAW CHART */
    _chart = () => {

        const { filter, start, end, waiting, chart, statistics, information } = this.state

        return (
            <div className="player-information-chart">

                {/* CHART HEAD */}
                <div className="player-content-statistics-head">
                    <div className="player-content-statistics-title">Выберите период</div>
                    <div className="player-content-statistics-head-filter">
                        <div onClick={() => this.setFilter("day")} className={`player-content-statistics-head-filter-item ${filter === "day" ? "active" : "disabled"}`}>День</div>
                        <div onClick={() => this.setFilter("week")} className={`player-content-statistics-head-filter-item ${filter === "week" ? "active" : "disabled"}`}>Неделя</div>
                        <div onClick={() => this.setFilter("month")} className={`player-content-statistics-head-filter-item ${filter === "month" ? "active" : "disabled"}`}>Месяц</div>
                    </div>
                </div>

                {/* DASHBOARD CHART FILTER */}
                <div className="player-content-statistics-filter">
                    <div onClick={() => this.go("prev")} className="player-content-statistics-filter-prev">
                        <img src="/images/left-arrow.png" alt="Left" />
                    </div>
                    <div className="player-content-statistics-filter-value">{datetime.modifyDate(filter, start, end)}</div>
                    <div onClick={() => this.go("next")} className="player-content-statistics-filter-next">
                        <img src="/images/right-arrow.png" alt="Right" />
                    </div>
                </div>

                <div className="chart-content-statistics-container-inner">
                    <ResponsiveContainer>
                        <AreaChart data={chart} margin={{ left: -20, right: 30 }} style={{ opacity: waiting ? 0.8 : 1 }}>
                            <defs>
                                <linearGradient id="colorRed" x1="0" y1="0" x2="0" y2="1">
                                    <stop offset="5%" stopColor="#f44336" stopOpacity={0.1} />
                                    <stop offset="95%" stopColor="#f44336" stopOpacity={0} />
                                </linearGradient>
                                <linearGradient id="colorGreen" x1="0" y1="0" x2="0" y2="1">
                                    <stop offset="5%" stopColor="#74c70a" stopOpacity={0.8} />
                                    <stop offset="95%" stopColor="#74c70a" stopOpacity={0} />
                                </linearGradient>
                            </defs>
                            <CartesianGrid strokeDasharray="2 2" />
                            <XAxis dataKey="name" tick={{ stroke: '#141414', strokeWidth: 0.2, fontSize: 10 }} />
                            <YAxis color="#fff" tick={{ stroke: '#141414', strokeWidth: 0.2, fontSize: 12 }} />
                            <Tooltip formatter={(value, name) => this.formatter(value, name)} />
                            <Area type="monotone" dataKey="credit" stroke="#f44336" strokeWidth={2} fill="url(#colorRed)" />
                            <Area type="monotone" dataKey="debit" stroke="#74c70a" strokeWidth={2} fill="url(#colorGreen)" />
                        </AreaChart>
                    </ResponsiveContainer>
                </div>

                {waiting && <LoadingOutlined />}

                {/* WIDGET */}
                {statistics &&
                    <div className="player-content-widget-container">

                        <div className="player-content-widget-container-title">Статистика за <span>{datetime.modifyDate(filter, start, end)}</span></div>

                        <div className="player-content-widget dark">

                            {/* DEDIT */}
                            <div className="player-content-widget-box">
                                <img src="/images/player/increase.png" alt="Increase" />
                                <div className="player-content-widget-text">
                                    <div className="player-content-widget-title">DEBIT</div>
                                    <div className="player-content-widget-value">+ {utils.convertor(statistics.debit, utils.checkValue(information.information.symbol, "$"), utils.checkValue(information.information.isAfter, 0), " ")}</div>
                                </div>
                            </div>

                            {/* CREDIT */}
                            <div className="player-content-widget-box">
                                <img src="/images/player/decrease.png" alt="Decrease" />
                                <div className="player-content-widget-text">
                                    <div className="player-content-widget-title">CREDIT</div>
                                    <div className="player-content-widget-value">- {utils.convertor(statistics.credit, utils.checkValue(information.information.symbol, "$"), utils.checkValue(information.information.isAfter, 0), " ")}</div>
                                </div>
                            </div>

                            {/* TOTAL */}
                            <div className="player-content-widget-box">
                                <img src="/images/player/calculate.png" alt="Calculate" />
                                <div className="player-content-widget-text">
                                    <div className="player-content-widget-title">TOTAL</div>
                                    <div className={`player-content-widget-value ${statistics.debit - statistics.credit >= 0 ? 'green' : 'red'}`}>
                                        {statistics.debit - statistics.credit >= 0 ? "+ " : "- "}
                                        {utils.convertor(Math.abs(statistics.debit - statistics.credit), utils.checkValue(information.information.symbol, "$"), utils.checkValue(information.information.isAfter, 0), " ")}
                                    </div>
                                </div>
                            </div>

                            {/* Games Count */}
                            <div className="player-content-widget-box">
                                <img src="/images/player/spades.png" alt="Spades" />
                                <div className="player-content-widget-text">
                                    <div className="player-content-widget-title">Количество игр</div>
                                    <div className="player-content-widget-value">{utils.checkValue(statistics.count, "0")}</div>
                                </div>
                            </div>

                            {/* Avg ANTE */}
                            <div className="player-content-widget-box">
                                <img src="/images/player/poker-chip.png" alt="Poker Chip" />
                                <div className="player-content-widget-text">
                                    <div className="player-content-widget-title">Средняя ставка ANTE</div>
                                    <div className="player-content-widget-value">{utils.convertor(parseInt(statistics.ante), utils.checkValue(information.information.symbol, "$"), utils.checkValue(information.information.isAfter, 0), " ")}</div>
                                </div>
                            </div>

                            {/* Total Time */}
                            <div className="player-content-widget-box">
                                <img src="/images/player/back-in-time.png" alt="Time" />
                                <div className="player-content-widget-text">
                                    <div className="player-content-widget-title">Общее время</div>
                                    <div className="player-content-widget-value">{utils.converTime(statistics.time)}</div>
                                </div>
                            </div>

                        </div>
                    </div>
                }

            </div>
        )
    }

    /* DRAW CHART */
    _content = () => {

        const { gameStatus, information, tab, game } = this.state

        if (gameStatus !== "ok") {
            return (
                <div className="player-content">
                    <InnerLayout status={gameStatus} reload={() => this.gameLoad(tab)} />
                </div>
            )
        }

        const index = information.data.findIndex(e => e.game === tab)

        if (index > -1) {

            const name = this.getGame(tab)
            const data = information.data[index]

            return (
                <div className="player-content">

                    {/* GAME NAME */}
                    <div className="player-content-title">{name}</div>
                    <div className="player-content-text">Статистика за все время</div>

                    {/* WIDGET */}
                    <div className="player-content-widget">

                        {/* DEDIT */}
                        <div className="player-content-widget-box">
                            <img src="/images/player/increase.png" alt="Increase" />
                            <div className="player-content-widget-text">
                                <div className="player-content-widget-title">DEBIT</div>
                                <div className="player-content-widget-value">+ {utils.convertor(data.debit, utils.checkValue(information.information.symbol, "$"), utils.checkValue(information.information.isAfter, 0), " ")}</div>
                            </div>
                        </div>

                        {/* CREDIT */}
                        <div className="player-content-widget-box">
                            <img src="/images/player/decrease.png" alt="Decrease" />
                            <div className="player-content-widget-text">
                                <div className="player-content-widget-title">CREDIT</div>
                                <div className="player-content-widget-value">- {utils.convertor(data.credit, utils.checkValue(information.information.symbol, "$"), utils.checkValue(information.information.isAfter, 0), " ")}</div>
                            </div>
                        </div>

                        {/* TOTAL */}
                        <div className="player-content-widget-box">
                            <img src="/images/player/calculate.png" alt="Calculate" />
                            <div className="player-content-widget-text">
                                <div className="player-content-widget-title">TOTAL</div>
                                <div className={`player-content-widget-value ${data.debit - data.credit >= 0 ? 'green' : 'red'}`}>
                                    {data.debit - data.credit >= 0 ? "+ " : "- "}
                                    {utils.convertor(Math.abs(data.debit - data.credit), utils.checkValue(information.information.symbol, "$"), utils.checkValue(information.information.isAfter, 0), " ")}
                                </div>
                            </div>
                        </div>

                        {/* Games Count */}
                        <div className="player-content-widget-box">
                            <img src="/images/player/spades.png" alt="Spades" />
                            <div className="player-content-widget-text">
                                <div className="player-content-widget-title">Количество игр</div>
                                <div className="player-content-widget-value">{utils.checkValue(game.count, "0")}</div>
                            </div>
                        </div>

                        {/* Avg ANTE */}
                        <div className="player-content-widget-box">
                            <img src="/images/player/poker-chip.png" alt="Poker Chip" />
                            <div className="player-content-widget-text">
                                <div className="player-content-widget-title">Средняя ставка ANTE</div>
                                <div className="player-content-widget-value">{utils.convertor(parseInt(game.ante), utils.checkValue(information.information.symbol, "$"), utils.checkValue(information.information.isAfter, 0), " ")}</div>
                            </div>
                        </div>

                        {/* Total Time */}
                        <div className="player-content-widget-box">
                            <img src="/images/player/back-in-time.png" alt="Time" />
                            <div className="player-content-widget-text">
                                <div className="player-content-widget-title">Общее время</div>
                                <div className="player-content-widget-value">{utils.converTime(information.time)}</div>
                            </div>
                        </div>

                    </div>

                    {/* DRAW CHART */}
                    {this._chart()}

                    {/* DRAW COMBINATIONS */}
                    {this._combinations(game.combinations)}

                </div>
            )
        }


    }


    render = () => {

        const { status, information } = this.state

        if (status !== "ok") {
            return <InnerLayout status={status} reload={() => this.load(true)} />
        }

        return (
            <div className="player-information">

                {/* INFORMATION WIDGET */}
                {this._information()}

                {/* Games */}
                {information.data && Array.isArray(information.data) && information.data.length > 0 &&
                    <div className="player-information-games">

                        {/* GAME TABS */}
                        {this._tabs()}

                        {/* GAME CONTENT */}
                        {this._content()}

                    </div>
                }

            </div>
        )
    }

}

export default Information