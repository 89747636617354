import React, { Component } from "react"


/* Component Tag */
class Tag extends Component {

    render = () => {
        const { children, color, right, uppercase } = this.props
        return <div className={`tag ${color}`} style={{ marginRight: right ? right : 0, textTransform: uppercase ? "uppercase" : "none" }}>{children}</div>
    }

}

export default Tag