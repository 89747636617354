import React, { Component } from "react"

/* Components */
import { InnerLayout } from "../../components"

/* Modal */
import Modal from 'react-modal'

/* REST API */
import { bonus } from "../../api/Bonus"

/* Helpers */
import { utils } from "../../helpers"

/* Widgets */
import JackpotUpdate from "./JackpotUpdate"
import JackpotConnect from "./JackpotConnect"


/* Widget Jackpot Modal */
class JackpotModal extends Component {

    constructor() {
        super()

        this.state = {
            data: [],
            status: "loading",

            update: false,
            active: null,

            create: false
        }
    }

    componentDidMount = () => {
        this.load()
    }

    /* Load Action */
    load = () => {
        bonus().then(response => {
            if (response.status === 200) {
                this.setState({ status: "ok", data: response.data })
            }
            else if (response.status === 401 || response.status === 403) {
                this.setState({ status: "permission", data: [] })
            }
            else if (response.status === 498) {
                this.setState({ status: "key", data: [] })
            }
            else {
                this.setState({ status: "error", data: [] })
            }
        }).catch(() => {
            this.setState({ status: "network", data: [] })
        })
    }

    /* Draw Content */
    _content = () => {

        const { status, data } = this.state

        if (status !== "ok") {
            return <InnerLayout status={status} reload={() => this.load()} />
        }

        if (data.length === 0) {
            return (
                <div className="bonus-jackpot--empty">
                    <img src="/images/bonus/jackpot.png" alt="Jackpot" />
                    <h2><span>JACKPOT</span> не подключен</h2>
                    <p>Подключите ДЖЕКПОТ к оператору</p>
                    <div onClick={() => this.setState({ create: true })} className="bonus-jackpot--empty-button">Подключить</div>
                </div>
            )
        }

        return (
            <div className="bonus-jackpot--container">

                {data.map((item, index) =>
                    <div onClick={() => this.setState({ active: item, update: true })} className="bonus-jackpot--operator" key={`${index}`}>

                        {/* Name */}
                        <div className="bonus-jackpot--operator-head">
                            <img src="/images/bonus/jackpot-king.png" alt="Jackpot King" />
                            <div className="bonus-jackpot--operator-name">{item.name}</div>
                        </div>

                        <div className="bonus-jackpot--operator-boxes">

                            <div className="bonus-jackpot--operator-box">
                                <div className="bonus-jackpot--operator-text">JACKPOT</div>
                                <div className="bonus-jackpot--operator-value">{utils.convertor(item.jackpot, "$", 0, " ")}</div>
                            </div>

                            <div className="bonus-jackpot--operator-box">
                                <div className="bonus-jackpot--operator-text">КАССА</div>
                                <div className="bonus-jackpot--operator-value score">{utils.convertor(item.score, "$", 0, " ")}</div>
                            </div>

                        </div>

                        <div className="bonus-jackpot--operator-more">
                            <img src="/images/bonus/more.png" alt="More" />
                        </div>

                        <div className={`bonus-jackpot--operator-status ${item.enabled === 1 ? "green" : "red"}`}>
                            {item.enabled === 1 ? "Активен" : "Отключен"}
                        </div>

                    </div>
                )}

                <div onClick={() => this.setState({ create: true })} className="bonus-jackpot--operator-create">
                    <img src="/images/bonus/plus.png" alt="Plus" />
                    <p>Подключить</p>
                </div>

            </div>
        )
    }

    /* Close Action */
    close = () => {

        /* Fields */
        const { close } = this.props

        /* Close */
        close()
    }


    render = () => {

        /* Fields */
        const { visible, socket } = this.props
        const { update, active, create } = this.state

        return (
            <Modal
                isOpen={visible}
                onRequestClose={() => this.close()}
                className="bonus-jackpot--modal"
                overlayClassName="bonus-jackpot--overlay"
                closeTimeoutMS={200}
            >

                <div className="bonus-jackpot--modal-header">
                    <h2>Настройки JACKPOT</h2>
                    <div onClick={() => this.close()} className="bonus-jackpot--modal-close">
                        <img src="/images/close.png" alt="Close" />
                    </div>
                </div>

                {this._content()}

                {update &&
                    <JackpotUpdate
                        visible={update}
                        data={active}
                        close={() => this.setState({ update: false, active: null }, () => this.load())}
                        socket={socket}
                    />
                }

                {create &&
                    <JackpotConnect
                        visible={create}
                        close={() => this.setState({ create: false }, () => this.load())}
                    />
                }

            </Modal>
        )
    }

}

export default JackpotModal